export const LegoBoxUpdates = [
  {
    id: '2024.4.1.2',
    title: 'New content update out - 2024.4.1.2',
    date: '2024/11/13 16:00',
    isExpanded: true,
    isCurrent: true,
    data: [
      10297,
      10300,
      10302,
      10303,
      10304,
      10305,
      10306,
      10308,
      10309,
      10312,
      10313,
      10314,
      10315,
      10317,
      10320,
      10321,
      10323,
      10325,
      10326,
      10328,
      10329,
      10332,
      10333,
      10334,
      10335,
      10337,
      10338,
      10339,
      10340,
      10341,
      10391,
      10412,
      10414,
      10415,
      10422,
      10423,
      10426,
      10427,
      10428,
      10432,
      10433,
      10435,
      10438,
      10785,
      10786,
      10787,
      10788,
      10796,
      10797,
      21060,
      21061,
      21240,
      21241,
      21242,
      21243,
      21244,
      21245,
      21246,
      21247,
      21248,
      21249,
      21250,
      21252,
      21253,
      21254,
      21255,
      21256,
      21257,
      21260,
      21261,
      21262,
      21263,
      21264,
      21265,
      21331,
      21332,
      21335,
      21336,
      21338,
      21339,
      21340,
      21341,
      21342,
      21343,
      21344,
      21348,
      21349,
      21350,
      21351,
      21352,
      31124,
      31125,
      31126,
      31129,
      31133,
      31134,
      31135,
      31136,
      31137,
      31138,
      31139,
      31140,
      31141,
      31142,
      31143,
      31144,
      31146,
      31147,
      31148,
      31149,
      31150,
      31151,
      31152,
      31153,
      31155,
      31156,
      31157,
      31208,
      31209,
      31210,
      31213,
      40725,
      40747,
      41724,
      41725,
      41727,
      41728,
      41730,
      41731,
      41734,
      41735,
      41736,
      41737,
      41738,
      41739,
      41740,
      41744,
      41745,
      41746,
      41747,
      41748,
      41749,
      41752,
      41753,
      41754,
      41755,
      41756,
      41757,
      41758,
      41759,
      41760,
      41838,
      41839,
      42129,
      42130,
      42136,
      42138,
      42141,
      42143,
      42145,
      42147,
      42148,
      42149,
      42150,
      42151,
      42152,
      42153,
      42154,
      42155,
      42156,
      42157,
      42158,
      42159,
      42161,
      42162,
      42163,
      42164,
      42166,
      42167,
      42168,
      42172,
      42173,
      42175,
      42176,
      42177,
      42182,
      42184,
      42196,
      42601,
      42602,
      42603,
      42604,
      42605,
      42609,
      42610,
      42613,
      42614,
      42615,
      42616,
      42617,
      42618,
      42619,
      42620,
      42621,
      42624,
      42626,
      42630,
      42632,
      42633,
      42634,
      42636,
      42638,
      42639,
      43223,
      43224,
      43231,
      43233,
      43234,
      43238,
      43240,
      43241,
      43244,
      43245,
      43246,
      43247,
      43248,
      43251,
      43252,
      43254,
      60312,
      60316,
      60335,
      60336,
      60337,
      60362,
      60363,
      60364,
      60365,
      60366,
      60367,
      60368,
      60369,
      60370,
      60372,
      60373,
      60374,
      60375,
      60376,
      60377,
      60378,
      60379,
      60380,
      60383,
      60384,
      60386,
      60387,
      60388,
      60389,
      60391,
      60392,
      60395,
      60396,
      60397,
      60402,
      60403,
      60404,
      60405,
      60406,
      60407,
      60408,
      60409,
      60412,
      60413,
      60414,
      60415,
      60417,
      60419,
      60420,
      60421,
      60422,
      60423,
      60426,
      60429,
      60430,
      60431,
      60432,
      60433,
      60434,
      60435,
      60437,
      60438,
      60439,
      60440,
      71453,
      71454,
      71455,
      71456,
      71457,
      71458,
      71459,
      71460,
      71461,
      71469,
      71472,
      71475,
      71476,
      71477,
      71478,
      71479,
      71480,
      71481,
      71483,
      71484,
      71485,
      71486,
      71759,
      71762,
      71770,
      71773,
      71780,
      71782,
      71783,
      71784,
      71785,
      71786,
      71787,
      71788,
      71789,
      71790,
      71791,
      71792,
      71793,
      71794,
      71795,
      71796,
      71797,
      71798,
      71800,
      71804,
      71806,
      71807,
      71808,
      71809,
      71814,
      71818,
      71820,
      71821,
      71822,
      75325,
      75328,
      75333,
      75337,
      75341,
      75344,
      75345,
      75346,
      75347,
      75348,
      75349,
      75350,
      75351,
      75352,
      75353,
      75354,
      75355,
      75356,
      75357,
      75358,
      75359,
      75360,
      75361,
      75362,
      75363,
      75364,
      75365,
      75368,
      75369,
      75370,
      75371,
      75372,
      75373,
      75374,
      75378,
      75380,
      75381,
      75382,
      75383,
      75384,
      75386,
      75388,
      75393,
      75394,
      75396,
      75398,
      75580,
      75581,
      75582,
      75583,
      76271,
      76272,
      76273,
      76274,
      76276,
      76277,
      76278,
      76279,
      76280,
      76281,
      76284,
      76285,
      76286,
      76288,
      76289,
      76290,
      76291,
      76292,
      76295,
      76296,
      76297,
      76298,
      76402,
      76406,
      76407,
      76413,
      76415,
      76416,
      76417,
      76419,
      76420,
      76421,
      76422,
      76423,
      76427,
      76431,
      76433,
      76435,
      76437,
      76439,
      76440,
      76780,
      76781,
      76914,
      76915,
      76916,
      76917,
      76918,
      76923,
      76924,
      76925,
      76962,
      76963,
      76964,
      76965,
      76966,
      76990,
      76991,
      76992,
      76993,
      76994,
      76995,
      76996,
      76997,
      76998,
      76999,
      77000,
      77070,
      77071,
      77072,
      77073,
      80050,
      80051,
      80053,
      80054,
      80055,
      80056,
      80057,
      80058,
      80112,
      80113,
    ],
  },
  {
    id: '2024.3.1.5',
    title: 'New content update out - 2024.3.1.5',
    date: '2024/10/02 17:00',
    isExpanded: false,
    isCurrent: false,
    data: [
      10297,
      10300,
      10302,
      10303,
      10304,
      10305,
      10306,
      10308,
      10309,
      10312,
      10313,
      10314,
      10315,
      10317,
      10320,
      10321,
      10323,
      10325,
      10326,
      10328,
      10329,
      10332,
      10333,
      10334,
      10337,
      10338,
      10339,
      10340,
      10341,
      10391,
      10412,
      10414,
      10415,
      10422,
      10423,
      10432,
      10433,
      10435,
      10438,
      10785,
      10786,
      10787,
      10788,
      10796,
      10797,
      21060,
      21061,
      21240,
      21241,
      21242,
      21243,
      21244,
      21245,
      21246,
      21247,
      21248,
      21249,
      21250,
      21252,
      21253,
      21254,
      21255,
      21256,
      21257,
      21260,
      21261,
      21262,
      21263,
      21264,
      21265,
      21331,
      21332,
      21335,
      21336,
      21338,
      21339,
      21340,
      21341,
      21342,
      21343,
      21344,
      21348,
      21349,
      21350,
      21351,
      21352,
      31124,
      31125,
      31126,
      31129,
      31133,
      31134,
      31135,
      31136,
      31137,
      31138,
      31139,
      31140,
      31141,
      31142,
      31143,
      31144,
      31146,
      31147,
      31148,
      31149,
      31150,
      31151,
      31152,
      31153,
      31155,
      31156,
      31157,
      31208,
      31209,
      31210,
      31213,
      40725,
      40747,
      41724,
      41725,
      41727,
      41728,
      41730,
      41731,
      41734,
      41735,
      41736,
      41737,
      41738,
      41739,
      41740,
      41744,
      41745,
      41746,
      41747,
      41748,
      41749,
      41752,
      41753,
      41754,
      41755,
      41756,
      41757,
      41758,
      41759,
      41760,
      41838,
      41839,
      42129,
      42130,
      42136,
      42138,
      42141,
      42143,
      42145,
      42147,
      42148,
      42149,
      42150,
      42151,
      42152,
      42153,
      42154,
      42155,
      42156,
      42157,
      42158,
      42159,
      42161,
      42162,
      42163,
      42164,
      42166,
      42167,
      42168,
      42172,
      42173,
      42175,
      42176,
      42177,
      42182,
      42184,
      42196,
      42601,
      42602,
      42603,
      42604,
      42605,
      42609,
      42610,
      42613,
      42614,
      42615,
      42616,
      42617,
      42618,
      42619,
      42620,
      42621,
      42624,
      42626,
      42630,
      42632,
      42633,
      42634,
      42636,
      42638,
      42639,
      43223,
      43224,
      43231,
      43233,
      43234,
      43238,
      43240,
      43241,
      43244,
      43245,
      43246,
      43247,
      43248,
      43251,
      43252,
      43254,
      60312,
      60316,
      60335,
      60336,
      60337,
      60362,
      60363,
      60364,
      60365,
      60366,
      60367,
      60368,
      60369,
      60370,
      60372,
      60373,
      60374,
      60375,
      60376,
      60377,
      60378,
      60379,
      60380,
      60383,
      60384,
      60386,
      60387,
      60388,
      60389,
      60391,
      60392,
      60395,
      60396,
      60397,
      60402,
      60403,
      60404,
      60405,
      60406,
      60407,
      60408,
      60409,
      60412,
      60413,
      60414,
      60415,
      60417,
      60419,
      60420,
      60421,
      60422,
      60423,
      60426,
      60429,
      60430,
      60431,
      60432,
      60433,
      60434,
      60435,
      60437,
      60438,
      60439,
      60440,
      71453,
      71454,
      71455,
      71456,
      71457,
      71458,
      71459,
      71460,
      71461,
      71469,
      71472,
      71475,
      71476,
      71477,
      71478,
      71479,
      71480,
      71481,
      71483,
      71484,
      71485,
      71486,
      71759,
      71762,
      71770,
      71773,
      71780,
      71782,
      71783,
      71784,
      71785,
      71786,
      71787,
      71788,
      71789,
      71790,
      71791,
      71792,
      71793,
      71794,
      71795,
      71796,
      71797,
      71798,
      71800,
      71804,
      71806,
      71807,
      71808,
      71809,
      71814,
      71818,
      71820,
      71821,
      71822,
      75325,
      75328,
      75333,
      75337,
      75341,
      75344,
      75345,
      75346,
      75347,
      75348,
      75349,
      75350,
      75351,
      75352,
      75353,
      75354,
      75355,
      75356,
      75357,
      75358,
      75359,
      75360,
      75361,
      75362,
      75363,
      75364,
      75365,
      75368,
      75369,
      75370,
      75371,
      75372,
      75373,
      75374,
      75378,
      75380,
      75381,
      75382,
      75383,
      75384,
      75386,
      75388,
      75393,
      75394,
      75396,
      75398,
      75580,
      75581,
      75582,
      75583,
      76271,
      76272,
      76273,
      76274,
      76276,
      76277,
      76278,
      76279,
      76280,
      76281,
      76284,
      76285,
      76286,
      76288,
      76289,
      76290,
      76291,
      76295,
      76297,
      76298,
      76402,
      76406,
      76407,
      76413,
      76415,
      76416,
      76417,
      76419,
      76420,
      76421,
      76422,
      76423,
      76427,
      76431,
      76433,
      76435,
      76437,
      76439,
      76440,
      76781,
      76914,
      76915,
      76916,
      76917,
      76918,
      76923,
      76924,
      76925,
      76962,
      76963,
      76964,
      76965,
      76966,
      76990,
      76991,
      76992,
      76993,
      76994,
      76995,
      76996,
      76997,
      76998,
      76999,
      77000,
      80050,
      80051,
      80053,
      80054,
      80055,
      80056,
      80057,
      80058,
      80112,
      80113
    ]
  },
  {
    id: '2024.2.1.3',
    title: 'New content update out - 2024.2.1.3',
    date: '2024/06/12 17:00',
    isExpanded: false,
    isCurrent: false,
    data: [
      10297,
      10300,
      10302,
      10303,
      10304,
      10305,
      10306,
      10308,
      10309,
      10312,
      10313,
      10314,
      10315,
      10317,
      10320,
      10321,
      10323,
      10325,
      10326,
      10328,
      10329,
      10332,
      10334,
      10337,
      10338,
      10412,
      10414,
      10415,
      10422,
      10423,
      10432,
      10433,
      10435,
      10438,
      10785,
      10786,
      10787,
      10788,
      10796,
      10797,
      21060,
      21061,
      21240,
      21241,
      21242,
      21243,
      21244,
      21245,
      21246,
      21247,
      21248,
      21249,
      21250,
      21252,
      21253,
      21254,
      21255,
      21256,
      21257,
      21260,
      21261,
      21262,
      21263,
      21264,
      21265,
      21331,
      21332,
      21335,
      21336,
      21338,
      21339,
      21340,
      21341,
      21342,
      21343,
      21344,
      21349,
      31124,
      31125,
      31126,
      31129,
      31133,
      31134,
      31135,
      31136,
      31137,
      31138,
      31139,
      31140,
      31141,
      31142,
      31143,
      31144,
      31146,
      31147,
      31148,
      31149,
      31150,
      31151,
      31152,
      31153,
      31155,
      31156,
      31157,
      31208,
      31209,
      31210,
      31213,
      40725,
      40747,
      41724,
      41725,
      41727,
      41728,
      41730,
      41731,
      41734,
      41735,
      41736,
      41737,
      41738,
      41739,
      41740,
      41744,
      41745,
      41746,
      41747,
      41748,
      41749,
      41752,
      41753,
      41754,
      41755,
      41756,
      41757,
      41758,
      41759,
      41760,
      42129,
      42130,
      42136,
      42138,
      42141,
      42143,
      42145,
      42147,
      42148,
      42149,
      42150,
      42151,
      42152,
      42153,
      42154,
      42155,
      42156,
      42157,
      42158,
      42159,
      42161,
      42162,
      42163,
      42164,
      42166,
      42167,
      42168,
      42173,
      42175,
      42176,
      42182,
      42184,
      42196,
      42601,
      42602,
      42603,
      42604,
      42605,
      42609,
      42610,
      42613,
      42614,
      42615,
      42616,
      42617,
      42618,
      42619,
      42620,
      42621,
      42624,
      42626,
      42630,
      42632,
      42633,
      42634,
      42636,
      42638,
      42639,
      43223,
      43224,
      43231,
      43233,
      43234,
      43238,
      43240,
      43241,
      43244,
      43245,
      43246,
      43247,
      43248,
      43251,
      43252,
      43254,
      60312,
      60316,
      60335,
      60336,
      60337,
      60362,
      60363,
      60364,
      60365,
      60366,
      60367,
      60368,
      60369,
      60370,
      60372,
      60373,
      60374,
      60375,
      60376,
      60377,
      60378,
      60379,
      60380,
      60383,
      60384,
      60386,
      60387,
      60388,
      60389,
      60391,
      60392,
      60395,
      60396,
      60397,
      60402,
      60403,
      60404,
      60405,
      60406,
      60407,
      60408,
      60409,
      60412,
      60413,
      60414,
      60415,
      60417,
      60419,
      60420,
      60421,
      60422,
      60423,
      60426,
      60429,
      60430,
      60431,
      60432,
      60433,
      60434,
      60435,
      60437,
      60438,
      60439,
      60440,
      71453,
      71454,
      71455,
      71456,
      71457,
      71458,
      71459,
      71460,
      71461,
      71469,
      71472,
      71475,
      71476,
      71477,
      71478,
      71479,
      71480,
      71481,
      71483,
      71484,
      71485,
      71486,
      71759,
      71762,
      71770,
      71773,
      71780,
      71782,
      71783,
      71784,
      71785,
      71786,
      71787,
      71788,
      71789,
      71790,
      71791,
      71792,
      71793,
      71794,
      71795,
      71796,
      71797,
      71798,
      71800,
      71804,
      71806,
      71807,
      71808,
      71809,
      71818,
      71820,
      71821,
      71822,
      75325,
      75328,
      75333,
      75337,
      75341,
      75344,
      75345,
      75346,
      75347,
      75348,
      75349,
      75350,
      75351,
      75352,
      75353,
      75354,
      75355,
      75356,
      75357,
      75358,
      75359,
      75360,
      75361,
      75362,
      75363,
      75364,
      75365,
      75368,
      75369,
      75370,
      75371,
      75372,
      75373,
      75378,
      75380,
      75381,
      75382,
      75383,
      75384,
      75386,
      75394,
      75580,
      75581,
      75582,
      75583,
      76271,
      76272,
      76273,
      76274,
      76276,
      76277,
      76278,
      76279,
      76280,
      76281,
      76284,
      76285,
      76286,
      76288,
      76289,
      76290,
      76291,
      76297,
      76298,
      76402,
      76406,
      76407,
      76413,
      76415,
      76416,
      76417,
      76419,
      76420,
      76421,
      76422,
      76423,
      76427,
      76431,
      76433,
      76435,
      76439,
      76440,
      76914,
      76915,
      76916,
      76917,
      76918,
      76923,
      76924,
      76925,
      76962,
      76963,
      76964,
      76965,
      76966,
      76990,
      76991,
      76992,
      76993,
      76994,
      76995,
      76996,
      76997,
      76998,
      80050,
      80051,
      80053,
      80054,
      80055,
      80056,
      80057,
      80058,
      80112,
      80113,
    ],
  },
  {
    id: '2024.1.1.2',
    title: 'New content update out - 2024.1.1.2',
    date: '2024/02/08 09:30',
    isExpanded: false,
    isCurrent: false,
    data: [
      10297,
      10300,
      10302,
      10303,
      10304,
      10305,
      10306,
      10308,
      10309,
      10312,
      10313,
      10314,
      10315,
      10317,
      10320,
      10321,
      10323,
      10325,
      10326,
      10328,
      10329,
      10412,
      10414,
      10415,
      10422,
      10423,
      10438,
      10785,
      10786,
      10787,
      10788,
      10789,
      10791,
      10935,
      10981,
      10982,
      10983,
      10984,
      10985,
      10986,
      10987,
      10988,
      10989,
      10990,
      10991,
      10992,
      10993,
      10994,
      10995,
      10996,
      10997,
      10998,
      11027,
      11028,
      11029,
      11030,
      11032,
      11033,
      11034,
      11035,
      11036,
      11037,
      21060,
      21240,
      21241,
      21242,
      21243,
      21244,
      21245,
      21246,
      21247,
      21248,
      21249,
      21250,
      21252,
      21253,
      21254,
      21255,
      21256,
      21257,
      21331,
      21332,
      21335,
      21336,
      21338,
      21339,
      21340,
      21341,
      21342,
      21343,
      21344,
      31124,
      31125,
      31126,
      31129,
      31133,
      31134,
      31135,
      31136,
      31137,
      31138,
      31139,
      31140,
      31141,
      31142,
      31143,
      31144,
      31146,
      31147,
      31148,
      31149,
      31150,
      31152,
      31155,
      31156,
      31157,
      31208,
      31209,
      31210,
      40725,
      40747,
      41724,
      41725,
      41727,
      41728,
      41730,
      41731,
      41734,
      41735,
      41736,
      41737,
      41738,
      41739,
      41740,
      41744,
      41745,
      41746,
      41747,
      41748,
      41749,
      41752,
      41753,
      41754,
      41755,
      41756,
      41757,
      41758,
      41759,
      41760,
      42129,
      42130,
      42136,
      42138,
      42141,
      42143,
      42145,
      42147,
      42148,
      42149,
      42150,
      42151,
      42152,
      42153,
      42154,
      42155,
      42156,
      42157,
      42158,
      42159,
      42161,
      42162,
      42163,
      42164,
      42166,
      42167,
      42168,
      42601,
      42603,
      42604,
      42605,
      42609,
      42610,
      42613,
      42614,
      42615,
      42616,
      42617,
      42619,
      42620,
      42621,
      42632,
      42633,
      42634,
      42639,
      43203,
      43205,
      43206,
      43210,
      43211,
      43212,
      43213,
      43214,
      43215,
      43216,
      43217,
      43218,
      43219,
      43220,
      43221,
      43222,
      43223,
      43224,
      43225,
      43226,
      43227,
      43229,
      43230,
      43231,
      43232,
      43233,
      43234,
      43238,
      43241,
      43246,
      60312,
      60316,
      60335,
      60336,
      60337,
      60362,
      60363,
      60364,
      60365,
      60366,
      60367,
      60368,
      60369,
      60370,
      60372,
      60373,
      60374,
      60375,
      60376,
      60377,
      60378,
      60379,
      60380,
      60383,
      60384,
      60386,
      60387,
      60388,
      60389,
      60391,
      60392,
      60395,
      60396,
      60397,
      60402,
      60403,
      60404,
      60405,
      60406,
      60412,
      60413,
      60414,
      60415,
      60417,
      60419,
      60429,
      60430,
      60431,
      60432,
      60433,
      60434,
      60438,
      71037,
      71038,
      71453,
      71454,
      71455,
      71456,
      71457,
      71458,
      71459,
      71460,
      71461,
      71469,
      71472,
      71475,
      71476,
      71477,
      71759,
      71762,
      71770,
      71773,
      71780,
      71782,
      71783,
      71784,
      71785,
      71786,
      71787,
      71788,
      71789,
      71790,
      71791,
      71792,
      71793,
      71794,
      71795,
      71796,
      71797,
      71798,
      71800,
      71804,
      71806,
      71807,
      71808,
      71809,
      75325,
      75328,
      75333,
      75337,
      75341,
      75344,
      75345,
      75346,
      75347,
      75348,
      75349,
      75350,
      75351,
      75352,
      75353,
      75354,
      75355,
      75356,
      75357,
      75358,
      75359,
      75360,
      75361,
      75362,
      75363,
      75364,
      75365,
      75368,
      75369,
      75370,
      75371,
      75372,
      75384,
      75575,
      75576,
      75579,
      76217,
      76218,
      76223,
      76224,
      76226,
      76241,
      76243,
      76244,
      76245,
      76247,
      76248,
      76249,
      76250,
      76251,
      76254,
      76256,
      76257,
      76258,
      76260,
      76261,
      76262,
      76263,
      76264,
      76265,
      76266,
      76276,
      76277,
      76278,
      76279,
      76280,
      76281,
      76285,
      76402,
      76406,
      76407,
      76413,
      76415,
      76416,
      76417,
      76419,
      76420,
      76421,
      76422,
      76423,
      76914,
      76915,
      76916,
      76917,
      76918,
      76957,
      76958,
      76959,
      76960,
      76961,
      76963,
      76964,
      76990,
      76991,
      76992,
      76993,
      76994,
      76995,
      76996,
      77012,
      77013,
      77015,
      80040,
      80041,
      80043,
      80044,
      80045,
      80046,
      80047,
      80048,
      80049,
      80050,
      80051,
      80053,
      80054,
      80112,
      80113,
    ],
  },
  {
    id: '2023.2.1.21',
    title: 'New content update out - 2023.2.1.21',
    date: '2023/09/20 09:30',
    isExpanded: false,
    isCurrent: false,
    data: [
      10297,
      10299,
      10300,
      10302,
      10303,
      10304,
      10305,
      10306,
      10308,
      10309,
      10312,
      10313,
      10314,
      10315,
      10317,
      10320,
      10321,
      10323,
      10497,
      10785,
      10786,
      10787,
      10788,
      10789,
      10790,
      10791,
      10935,
      10981,
      10982,
      10983,
      10984,
      10985,
      10986,
      10987,
      10988,
      10989,
      10990,
      10991,
      10992,
      10993,
      10994,
      10995,
      10996,
      10997,
      10998,
      11027,
      11028,
      11029,
      11030,
      11031,
      11032,
      11033,
      21060,
      21240,
      21241,
      21242,
      21243,
      21244,
      21245,
      21246,
      21247,
      21248,
      21249,
      21250,
      21331,
      21332,
      21335,
      21336,
      21337,
      21338,
      21339,
      21340,
      21341,
      21342,
      31123,
      31124,
      31125,
      31126,
      31127,
      31128,
      31129,
      31130,
      31131,
      31132,
      31133,
      31134,
      31135,
      31136,
      31137,
      31138,
      31139,
      31140,
      31141,
      31142,
      31143,
      31144,
      31205,
      31206,
      31207,
      31208,
      31209,
      31210,
      41696,
      41697,
      41699,
      41703,
      41704,
      41705,
      41707,
      41708,
      41711,
      41712,
      41714,
      41715,
      41716,
      41717,
      41718,
      41719,
      41720,
      41721,
      41723,
      41724,
      41725,
      41726,
      41727,
      41728,
      41729,
      41730,
      41731,
      41732,
      41733,
      41734,
      41735,
      41736,
      41737,
      41738,
      41739,
      41740,
      41741,
      41742,
      41743,
      41744,
      41745,
      41746,
      41747,
      41748,
      41749,
      41751,
      41752,
      41753,
      41754,
      41755,
      41756,
      41757,
      41758,
      41759,
      41760,
      41801,
      41802,
      41803,
      41805,
      41806,
      41807,
      41808,
      41809,
      41811,
      42127,
      42129,
      42130,
      42132,
      42133,
      42134,
      42135,
      42136,
      42137,
      42138,
      42139,
      42140,
      42141,
      42143,
      42144,
      42145,
      42147,
      42148,
      42149,
      42150,
      42151,
      42152,
      42153,
      42154,
      42155,
      42156,
      42157,
      42158,
      42159,
      42161,
      42162,
      43198,
      43199,
      43203,
      43204,
      43205,
      43206,
      43207,
      43208,
      43209,
      43210,
      43211,
      43212,
      43213,
      43214,
      43215,
      43216,
      43217,
      43218,
      43219,
      43220,
      43221,
      43222,
      43225,
      43226,
      43227,
      43229,
      43230,
      43232,
      60312,
      60314,
      60315,
      60316,
      60317,
      60318,
      60319,
      60320,
      60321,
      60335,
      60336,
      60337,
      60348,
      60349,
      60350,
      60351,
      60354,
      60355,
      60356,
      60357,
      60358,
      60359,
      60360,
      60361,
      60362,
      60363,
      60364,
      60365,
      60366,
      60367,
      60368,
      60369,
      60370,
      60371,
      60372,
      60373,
      60374,
      60375,
      60376,
      60377,
      60378,
      60379,
      60380,
      60381,
      60382,
      60383,
      60384,
      60385,
      60386,
      60387,
      60388,
      60389,
      60390,
      60391,
      60392,
      60393,
      60394,
      60395,
      60396,
      60397,
      60398,
      71037,
      71038,
      71453,
      71454,
      71455,
      71456,
      71457,
      71458,
      71459,
      71460,
      71461,
      71469,
      71757,
      71759,
      71760,
      71761,
      71762,
      71763,
      71765,
      71766,
      71767,
      71768,
      71769,
      71770,
      71771,
      71772,
      71773,
      71774,
      71775,
      71776,
      71777,
      71778,
      71779,
      71780,
      71781,
      71782,
      71783,
      71784,
      71785,
      71786,
      71787,
      71788,
      71789,
      71790,
      71791,
      71792,
      71793,
      71794,
      71795,
      71796,
      71797,
      71798,
      71800,
      75320,
      75322,
      75323,
      75324,
      75325,
      75326,
      75327,
      75328,
      75332,
      75333,
      75334,
      75335,
      75336,
      75337,
      75338,
      75341,
      75343,
      75344,
      75345,
      75346,
      75347,
      75348,
      75349,
      75350,
      75351,
      75352,
      75353,
      75354,
      75355,
      75356,
      75357,
      75358,
      75359,
      75360,
      75361,
      75362,
      75363,
      75364,
      75365,
      75366,
      75368,
      75369,
      75370,
      75371,
      75575,
      75576,
      75577,
      75578,
      75579,
      76181,
      76206,
      76209,
      76211,
      76212,
      76213,
      76214,
      76215,
      76216,
      76217,
      76218,
      76223,
      76224,
      76225,
      76226,
      76230,
      76241,
      76242,
      76243,
      76244,
      76245,
      76247,
      76248,
      76249,
      76250,
      76251,
      76253,
      76254,
      76255,
      76256,
      76257,
      76258,
      76260,
      76261,
      76262,
      76263,
      76264,
      76265,
      76266,
      76267,
      76398,
      76399,
      76400,
      76401,
      76402,
      76403,
      76406,
      76407,
      76408,
      76409,
      76410,
      76411,
      76412,
      76413,
      76415,
      76416,
      76417,
      76418,
      76419,
      76420,
      76421,
      76422,
      76423,
      76914,
      76915,
      76916,
      76917,
      76918,
      76957,
      76958,
      76959,
      76960,
      76961,
      76989,
      76990,
      76991,
      76992,
      76993,
      76994,
      77012,
      77013,
      77015,
      80040,
      80041,
      80043,
      80044,
      80045,
      80046,
      80047,
      80048,
      80049,
      80110,
      80111,
    ],
  },
  {
    id: '2023.1.1.11',
    title: 'New content update out - 2023.1.1.11',
    date: '2023/01/10 14:30',
    isExpanded: false,
    isCurrent: false,
    data: [
      10255,
      10266,
      10270,
      10273,
      10278,
      10280,
      10281,
      10283,
      10289,
      10290,
      10292,
      10293,
      10295,
      10297,
      10299,
      10300,
      10302,
      10303,
      10304,
      10305,
      10306,
      10308,
      10309,
      10311,
      10411,
      10497,
      10713,
      10777,
      10780,
      10781,
      10782,
      10783,
      10784,
      10872,
      10874,
      10875,
      10882,
      10899,
      10909,
      10913,
      10914,
      10935,
      10938,
      10941,
      10944,
      10948,
      10954,
      10956,
      10959,
      10960,
      10962,
      10963,
      10964,
      10965,
      10966,
      10967,
      10968,
      10969,
      10970,
      10971,
      10972,
      10973,
      10974,
      10975,
      10976,
      10977,
      10978,
      10979,
      10985,
      10986,
      10990,
      10995,
      10996,
      11013,
      11014,
      11015,
      11017,
      11018,
      11019,
      11020,
      11021,
      11022,
      11031,
      21042,
      21044,
      21054,
      21056,
      21057,
      21058,
      21166,
      21170,
      21172,
      21177,
      21178,
      21179,
      21180,
      21181,
      21183,
      21184,
      21185,
      21186,
      21187,
      21188,
      21189,
      21190,
      21240,
      21241,
      21242,
      21243,
      21244,
      21245,
      21246,
      21318,
      21323,
      21325,
      21327,
      21329,
      21330,
      21331,
      21332,
      21333,
      21334,
      21335,
      21336,
      21337,
      31058,
      31088,
      31109,
      31111,
      31118,
      31120,
      31123,
      31124,
      31125,
      31126,
      31127,
      31128,
      31129,
      31130,
      31131,
      31132,
      31133,
      31143,
      31204,
      31205,
      31206,
      40554,
      40615,
      40630,
      40631,
      40632,
      41168,
      41694,
      41695,
      41696,
      41697,
      41698,
      41699,
      41700,
      41701,
      41702,
      41703,
      41704,
      41705,
      41706,
      41707,
      41708,
      41709,
      41710,
      41711,
      41711001,
      41712,
      41713,
      41713001,
      41714,
      41714001,
      41715,
      41716,
      41716001,
      41717,
      41717001,
      41718,
      41719,
      41720,
      41721,
      41723,
      41724,
      41726,
      41727,
      41728,
      41729,
      41730,
      41731,
      41732,
      41733,
      41735,
      41738,
      41739,
      41740,
      41741,
      41742,
      41743,
      41751,
      41754,
      41755,
      41801,
      41802,
      41803,
      41805,
      41806,
      41807,
      41942,
      41943,
      41944,
      41945,
      41946,
      41947,
      41948,
      41949,
      41950,
      41951,
      41952,
      41953,
      41954,
      41955,
      41956,
      41957,
      41958,
      41959,
      41960,
      41961,
      41962,
      41964,
      42096,
      42111,
      42115,
      42122,
      42123,
      42125,
      42127,
      42128,
      42129,
      42130,
      42131,
      42132,
      42133,
      42134,
      42135,
      42136,
      42137,
      42138,
      42139,
      42140,
      42141,
      42143,
      42144,
      42145,
      42147,
      42148,
      42149,
      42150,
      42151,
      43102,
      43103,
      43104,
      43105,
      43106,
      43107,
      43108,
      43109,
      43110,
      43111,
      43112,
      43113,
      43114,
      43115,
      43187,
      43194,
      43196,
      43197,
      43198,
      43199,
      43202,
      43203,
      43204,
      43205,
      43206,
      43207,
      43208,
      43209,
      43210,
      43211,
      43214,
      43216,
      51515,
      51515001,
      51515002,
      60198,
      60205,
      60283,
      60287,
      60296,
      60304,
      60309,
      60310,
      60311,
      60312,
      60313,
      60314,
      60315,
      60316,
      60317,
      60318,
      60319,
      60320,
      60321,
      60322,
      60323,
      60324,
      60325,
      60326,
      60327,
      60328,
      60329,
      60330,
      60331,
      60332,
      60333,
      60335,
      60336,
      60337,
      60338,
      60339,
      60340,
      60341,
      60342,
      60343,
      60344,
      60345,
      60346,
      60347,
      60348,
      60349,
      60350,
      60351,
      60352,
      60353,
      60354,
      60355,
      60356,
      60358,
      60369,
      60370,
      60371,
      60372,
      60373,
      60374,
      60375,
      60382,
      60383,
      60384,
      60385,
      60386,
      60387,
      60388,
      60389,
      60390,
      60392,
      60393,
      60394,
      70688,
      70689,
      70690,
      71360,
      71367,
      71374,
      71387,
      71388,
      71389,
      71390,
      71391,
      71392,
      71393,
      71395,
      71396,
      71397,
      71398,
      71399,
      71400,
      71401,
      71403,
      71404,
      71405,
      71406,
      71407,
      71408,
      71409,
      71410,
      71738,
      71741,
      71754,
      71756,
      71757,
      71759,
      71760,
      71761,
      71762,
      71763,
      71764,
      71765,
      71766,
      71767,
      71768,
      71769,
      71770,
      71771,
      71772,
      71773,
      71774,
      71775,
      71776,
      71780,
      71781,
      71782,
      71783,
      71784,
      71785,
      71786,
      71787,
      71788,
      71800,
      75192,
      75257,
      75288,
      75290,
      75292,
      75300,
      75301,
      75304,
      75308,
      75309,
      75312,
      75313,
      75320,
      75321,
      75322,
      75323,
      75324,
      75325,
      75326,
      75327,
      75328,
      75329,
      75330,
      75332,
      75333,
      75334,
      75335,
      75336,
      75337,
      75338,
      75339,
      75340,
      75341,
      75342,
      75343,
      75571,
      75572,
      75573,
      75574,
      75576,
      75577,
      75578,
      75579,
      75968,
      75969,
      75979,
      76178,
      76179,
      76181,
      76183,
      76187,
      76191,
      76193,
      76202,
      76203,
      76204,
      76205,
      76206,
      76207,
      76208,
      76209,
      76211,
      76212,
      76213,
      76214,
      76215,
      76216,
      76217,
      76218,
      76219,
      76220,
      76223,
      76225,
      76226,
      76230,
      76231,
      76240,
      76241,
      76242,
      76243,
      76244,
      76245,
      76247,
      76248,
      76388,
      76389,
      76391,
      76396,
      76397,
      76398,
      76399,
      76400,
      76401,
      76402,
      76403,
      76404,
      76405,
      76406,
      76407,
      76408,
      76830,
      76831,
      76832,
      76906,
      76907,
      76908,
      76909,
      76910,
      76911,
      76912,
      76917,
      76943,
      76946,
      76947,
      76948,
      76949,
      76950,
      76951,
      76956,
      76989,
      80008,
      80030,
      80031,
      80032,
      80033,
      80034,
      80035,
      80036,
      80037,
      80038,
      80039,
      80040,
      80041,
      80043,
      80044,
      80045,
      80108,
      80109,
      80110,
      80111,
    ],
  },
  {
    id: '2022.2.2.2',
    title: 'New content update out - 2022.2.2.2',
    date: '2022/07/28 16:05',
    isExpanded: false,
    isCurrent: false,
    data: [
      10255,
      10266,
      10270,
      10271,
      10272,
      10273,
      10275,
      10276,
      10278,
      10279,
      10280,
      10281,
      10283,
      10284,
      10289,
      10290,
      10291,
      10292,
      10293,
      10295,
      10297,
      10299,
      10300,
      10302,
      10303,
      10304,
      10305,
      10306,
      10309,
      10311,
      10497,
      10713,
      10714,
      10772,
      10773,
      10774,
      10775,
      10776,
      10777,
      10780,
      10781,
      10782,
      10783,
      10784,
      10872,
      10874,
      10875,
      10882,
      10899,
      10909,
      10913,
      10914,
      10915,
      10920,
      10929,
      10930,
      10934,
      10938,
      10939,
      10940,
      10941,
      10942,
      10943,
      10944,
      10945,
      10946,
      10947,
      10948,
      10950,
      10951,
      10952,
      10953,
      10954,
      10955,
      10956,
      10957,
      10959,
      10960,
      10961,
      10962,
      10963,
      10964,
      10965,
      10966,
      10967,
      10968,
      10969,
      10970,
      10971,
      10972,
      10973,
      10974,
      10975,
      10977,
      10978,
      10979,
      11006,
      11008,
      11010,
      11011,
      11012,
      11013,
      11014,
      11015,
      11016,
      11017,
      11018,
      11019,
      11020,
      11021,
      11022,
      11717,
      17101,
      21042,
      21044,
      21051,
      21054,
      21056,
      21057,
      21058,
      21158,
      21160,
      21161,
      21164,
      21165,
      21166,
      21167,
      21168,
      21169,
      21170,
      21171,
      21172,
      21173,
      21174,
      21177,
      21178,
      21179,
      21180,
      21181,
      21183,
      21184,
      21185,
      21186,
      21187,
      21188,
      21189,
      21190,
      21226,
      21318,
      21319,
      21321,
      21323,
      21324,
      21325,
      21327,
      21328,
      21329,
      21330,
      21331,
      21332,
      21333,
      21334,
      30329,
      30330,
      30331,
      30332,
      30343,
      30344,
      30366,
      30367,
      30368,
      30370,
      30385,
      30388,
      30391,
      30412,
      30413,
      30463,
      30465,
      30536,
      30537,
      30539,
      30558,
      30563,
      30564,
      30565,
      30566,
      30567,
      30568,
      30576,
      30577,
      30578,
      30579,
      30591,
      31058,
      31088,
      31100,
      31108,
      31109,
      31111,
      31112,
      31113,
      31114,
      31115,
      31116,
      31117,
      31118,
      31119,
      31120,
      31121,
      31122,
      31123,
      31124,
      31125,
      31126,
      31127,
      31128,
      31129,
      31130,
      31131,
      31132,
      31204,
      31205,
      31206,
      40373,
      40374,
      40377,
      40378,
      40393,
      40535,
      40536,
      40540,
      40541,
      40542,
      40543,
      40544,
      40545,
      40546,
      40547,
      40548,
      40549,
      40550,
      40552,
      40553,
      40559,
      40560,
      41166,
      41168,
      41393,
      41410,
      41411,
      41412,
      41413,
      41414,
      41438,
      41441,
      41443,
      41444,
      41448,
      41449,
      41450,
      41677,
      41679,
      41681,
      41682,
      41683,
      41684,
      41685,
      41686,
      41687,
      41688,
      41689,
      41691,
      41693,
      41694,
      41695,
      41696,
      41697,
      41698,
      41699,
      41700,
      41701,
      41702,
      41703,
      41704,
      41705,
      41707,
      41708,
      41709,
      41710,
      41711,
      41712,
      41713,
      41714,
      41715,
      41716,
      41717,
      41718,
      41719,
      41720,
      41721,
      41926,
      41935,
      41936,
      41937,
      41942,
      41943,
      41944,
      41945,
      41946,
      41947,
      41948,
      41949,
      41950,
      41951,
      41952,
      41953,
      41954,
      41955,
      41956,
      41957,
      41958,
      41959,
      41960,
      41961,
      41962,
      41964,
      42095,
      42096,
      42099,
      42100,
      42109,
      42110,
      42111,
      42114,
      42115,
      42117,
      42118,
      42119,
      42120,
      42121,
      42122,
      42123,
      42124,
      42125,
      42126,
      42127,
      42128,
      42129,
      42130,
      42131,
      42132,
      42133,
      42134,
      42135,
      42136,
      42137,
      42138,
      42139,
      42140,
      42141,
      42143,
      42144,
      42145,
      43101,
      43102,
      43103,
      43104,
      43105,
      43106,
      43107,
      43108,
      43109,
      43110,
      43111,
      43112,
      43113,
      43114,
      43115,
      43179,
      43187,
      43189,
      43192,
      43193,
      43194,
      43195,
      43196,
      43197,
      43198,
      43199,
      43200,
      43201,
      43202,
      43203,
      43204,
      43205,
      43206,
      43207,
      43208,
      43209,
      51515,
      60197,
      60198,
      60205,
      60243,
      60253,
      60262,
      60265,
      60275,
      60276,
      60277,
      60280,
      60281,
      60282,
      60283,
      60284,
      60287,
      60291,
      60292,
      60293,
      60294,
      60295,
      60296,
      60297,
      60298,
      60299,
      60300,
      60301,
      60302,
      60304,
      60305,
      60307,
      60308,
      60309,
      60310,
      60311,
      60312,
      60313,
      60314,
      60315,
      60316,
      60317,
      60318,
      60319,
      60320,
      60321,
      60322,
      60323,
      60324,
      60325,
      60326,
      60327,
      60328,
      60329,
      60330,
      60331,
      60332,
      60333,
      60335,
      60336,
      60337,
      60338,
      60339,
      60340,
      60341,
      60342,
      60343,
      60344,
      60345,
      60346,
      60347,
      60348,
      60349,
      60350,
      60351,
      60353,
      60354,
      60355,
      70688,
      70689,
      70690,
      71360,
      71367,
      71374,
      71387,
      71388,
      71389,
      71390,
      71391,
      71392,
      71393,
      71395,
      71396,
      71397,
      71398,
      71399,
      71400,
      71401,
      71403,
      71404,
      71405,
      71406,
      71407,
      71408,
      71409,
      71410,
      71734,
      71735,
      71736,
      71737,
      71738,
      71739,
      71740,
      71741,
      71742,
      71745,
      71746,
      71747,
      71748,
      71749,
      71750,
      71752,
      71753,
      71754,
      71755,
      71756,
      71757,
      71759,
      71760,
      71761,
      71762,
      71763,
      71764,
      71765,
      71766,
      71767,
      71768,
      71769,
      71770,
      71771,
      71772,
      71773,
      71774,
      71775,
      71776,
      75192,
      75252,
      75255,
      75257,
      75277,
      75280,
      75283,
      75288,
      75290,
      75292,
      75295,
      75296,
      75297,
      75298,
      75299,
      75300,
      75301,
      75302,
      75304,
      75305,
      75306,
      75308,
      75309,
      75310,
      75311,
      75312,
      75313,
      75314,
      75315,
      75316,
      75319,
      75320,
      75321,
      75322,
      75323,
      75324,
      75325,
      75326,
      75327,
      75328,
      75329,
      75330,
      75332,
      75333,
      75334,
      75336,
      75337,
      75338,
      75339,
      75341,
      75342,
      75343,
      75546,
      75547,
      75550,
      75968,
      75969,
      75979,
      75980,
      76154,
      76155,
      76156,
      76175,
      76178,
      76179,
      76180,
      76181,
      76182,
      76183,
      76184,
      76185,
      76186,
      76187,
      76188,
      76189,
      76190,
      76191,
      76192,
      76193,
      76194,
      76195,
      76198,
      76199,
      76200,
      76201,
      76202,
      76203,
      76204,
      76205,
      76206,
      76207,
      76208,
      76209,
      76216,
      76217,
      76218,
      76219,
      76223,
      76237,
      76238,
      76239,
      76240,
      76382,
      76383,
      76386,
      76387,
      76388,
      76389,
      76391,
      76392,
      76393,
      76394,
      76395,
      76396,
      76397,
      76398,
      76399,
      76400,
      76401,
      76402,
      76403,
      76406,
      76407,
      76408,
      76830,
      76831,
      76832,
      76900,
      76901,
      76902,
      76903,
      76904,
      76905,
      76906,
      76907,
      76908,
      76909,
      76910,
      76911,
      76912,
      76939,
      76940,
      76941,
      76942,
      76943,
      76946,
      76947,
      76948,
      76949,
      76950,
      76951,
      76956,
      76989,
      80008,
      80012,
      80013,
      80019,
      80020,
      80021,
      80022,
      80023,
      80024,
      80025,
      80026,
      80028,
      80030,
      80031,
      80032,
      80033,
      80034,
      80035,
      80036,
      80037,
      80038,
      80039,
      80108,
      80109,
    ],
  },
  {
    id: '2022.2.1.5',
    title: 'New content update out - 2022.2.1.5',
    date: '2022/05/30 14:30',
    isExpanded: false,
    isCurrent: false,
    data: [
      10255,
      10266,
      10270,
      10271,
      10272,
      10273,
      10275,
      10276,
      10278,
      10279,
      10280,
      10281,
      10283,
      10284,
      10289,
      10290,
      10291,
      10292,
      10293,
      10295,
      10297,
      10299,
      10300,
      10309,
      10311,
      10713,
      10714,
      10772,
      10773,
      10774,
      10775,
      10776,
      10777,
      10780,
      10781,
      10782,
      10783,
      10784,
      10872,
      10874,
      10875,
      10882,
      10899,
      10909,
      10913,
      10914,
      10915,
      10920,
      10929,
      10930,
      10934,
      10938,
      10939,
      10940,
      10941,
      10942,
      10943,
      10944,
      10945,
      10946,
      10947,
      10948,
      10950,
      10951,
      10952,
      10953,
      10954,
      10955,
      10956,
      10957,
      10959,
      10960,
      10961,
      10962,
      10963,
      10964,
      10965,
      10966,
      10967,
      10968,
      10970,
      10971,
      10972,
      10973,
      10974,
      10975,
      10977,
      10978,
      10979,
      11006,
      11008,
      11010,
      11011,
      11012,
      11013,
      11014,
      11015,
      11016,
      11017,
      11018,
      11019,
      11020,
      11021,
      11022,
      11717,
      17101,
      21042,
      21044,
      21051,
      21054,
      21056,
      21057,
      21058,
      21158,
      21160,
      21161,
      21164,
      21165,
      21166,
      21167,
      21168,
      21169,
      21170,
      21171,
      21172,
      21173,
      21174,
      21177,
      21178,
      21179,
      21180,
      21181,
      21183,
      21184,
      21185,
      21186,
      21187,
      21188,
      21226,
      21318,
      21319,
      21321,
      21323,
      21324,
      21325,
      21327,
      21328,
      21329,
      21330,
      21331,
      21332,
      30329,
      30330,
      30331,
      30332,
      30343,
      30344,
      30366,
      30367,
      30368,
      30370,
      30385,
      30388,
      30391,
      30412,
      30413,
      30463,
      30465,
      30536,
      30537,
      30539,
      30558,
      30563,
      30564,
      30565,
      30566,
      30567,
      30568,
      30576,
      30577,
      30578,
      30579,
      30591,
      31058,
      31088,
      31100,
      31108,
      31109,
      31111,
      31112,
      31113,
      31114,
      31115,
      31116,
      31117,
      31118,
      31119,
      31120,
      31121,
      31122,
      31123,
      31124,
      31125,
      31126,
      31127,
      31128,
      31129,
      31130,
      31131,
      31132,
      31204,
      31205,
      40373,
      40374,
      40377,
      40378,
      40393,
      40535,
      40536,
      40540,
      40543,
      40544,
      40548,
      40549,
      40550,
      40552,
      40553,
      40559,
      40560,
      41166,
      41168,
      41393,
      41410,
      41411,
      41412,
      41413,
      41414,
      41438,
      41441,
      41443,
      41444,
      41448,
      41449,
      41450,
      41677,
      41679,
      41681,
      41682,
      41683,
      41684,
      41685,
      41686,
      41687,
      41688,
      41689,
      41691,
      41693,
      41694,
      41695,
      41696,
      41697,
      41698,
      41699,
      41700,
      41701,
      41702,
      41703,
      41704,
      41705,
      41707,
      41708,
      41709,
      41710,
      41711,
      41712,
      41713,
      41714,
      41715,
      41716,
      41717,
      41718,
      41719,
      41720,
      41926,
      41935,
      41936,
      41937,
      41942,
      41943,
      41944,
      41945,
      41946,
      41948,
      41949,
      41950,
      41951,
      41952,
      41953,
      41954,
      41955,
      41958,
      41959,
      41960,
      41961,
      41962,
      42095,
      42096,
      42099,
      42100,
      42109,
      42110,
      42111,
      42114,
      42115,
      42117,
      42118,
      42119,
      42120,
      42121,
      42122,
      42123,
      42124,
      42125,
      42126,
      42127,
      42128,
      42129,
      42130,
      42131,
      42132,
      42133,
      42134,
      42135,
      42136,
      42137,
      42138,
      42139,
      42140,
      42141,
      43101,
      43101001,
      43101002,
      43101003,
      43101004,
      43101005,
      43101006,
      43102,
      43103,
      43104,
      43105,
      43106,
      43107,
      43108,
      43109,
      43110,
      43111,
      43112,
      43113,
      43114,
      43115,
      43179,
      43187,
      43189,
      43192,
      43193,
      43194,
      43195,
      43196,
      43197,
      43198,
      43199,
      43200,
      43201,
      43202,
      43203,
      43204,
      43206,
      43207,
      43208,
      43209,
      51515,
      51515001,
      51515002,
      60197,
      60198,
      60205,
      60243,
      60253,
      60262,
      60265,
      60275,
      60276,
      60277,
      60280,
      60281,
      60282,
      60283,
      60284,
      60287,
      60291,
      60292,
      60293,
      60294,
      60295,
      60296,
      60297,
      60298,
      60299,
      60300,
      60301,
      60302,
      60304,
      60305,
      60307,
      60308,
      60309,
      60310,
      60311,
      60312,
      60313,
      60314,
      60315,
      60316,
      60317,
      60318,
      60319,
      60320,
      60321,
      60322,
      60323,
      60324,
      60325,
      60326,
      60327,
      60328,
      60329,
      60330,
      60331,
      60332,
      60333,
      60335,
      60336,
      60337,
      60338,
      60340,
      60341,
      60342,
      60343,
      60344,
      60345,
      60346,
      60347,
      60348,
      60349,
      60350,
      60351,
      60353,
      60354,
      60355,
      70688,
      70689,
      70690,
      71360,
      71367,
      71374,
      71387,
      71388,
      71389,
      71390,
      71391,
      71392,
      71393,
      71395,
      71396,
      71397,
      71398,
      71399,
      71400,
      71401,
      71734,
      71735,
      71736,
      71737,
      71738,
      71739,
      71740,
      71741,
      71742,
      71745,
      71746,
      71747,
      71748,
      71749,
      71750,
      71752,
      71753,
      71754,
      71755,
      71756,
      71757,
      71759,
      71760,
      71761,
      71762,
      71763,
      71764,
      71765,
      71766,
      71767,
      71768,
      71769,
      71770,
      71771,
      71772,
      71776,
      75192,
      75252,
      75255,
      75257,
      75277,
      75280,
      75283,
      75288,
      75290,
      75292,
      75295,
      75296,
      75297,
      75298,
      75299,
      75300,
      75301,
      75302,
      75304,
      75305,
      75306,
      75308,
      75309,
      75310,
      75311,
      75312,
      75313,
      75314,
      75315,
      75316,
      75319,
      75320,
      75321,
      75322,
      75324,
      75325,
      75326,
      75327,
      75328,
      75329,
      75330,
      75339,
      75341,
      75342,
      75343,
      75546,
      75547,
      75550,
      75968,
      75969,
      75979,
      75980,
      76154,
      76155,
      76156,
      76175,
      76178,
      76179,
      76180,
      76181,
      76182,
      76183,
      76184,
      76185,
      76186,
      76187,
      76188,
      76189,
      76190,
      76191,
      76192,
      76193,
      76194,
      76195,
      76198,
      76199,
      76200,
      76201,
      76202,
      76203,
      76204,
      76205,
      76206,
      76207,
      76208,
      76209,
      76217,
      76219,
      76237,
      76238,
      76239,
      76240,
      76382,
      76383,
      76386,
      76387,
      76388,
      76389,
      76391,
      76392,
      76393,
      76394,
      76395,
      76396,
      76397,
      76398,
      76399,
      76400,
      76401,
      76402,
      76403,
      76830,
      76831,
      76832,
      76900,
      76901,
      76902,
      76903,
      76904,
      76905,
      76906,
      76907,
      76908,
      76909,
      76910,
      76939,
      76940,
      76941,
      76942,
      76943,
      76946,
      76947,
      76948,
      76949,
      76950,
      76951,
      76956,
      76989,
      80008,
      80012,
      80013,
      80019,
      80020,
      80021,
      80022,
      80023,
      80024,
      80025,
      80026,
      80028,
      80030,
      80031,
      80032,
      80033,
      80034,
      80035,
      80036,
      80037,
      80038,
      80039,
      80108,
      80109,
    ],
  },
  {
    id: '2022.1.2.2',
    title: 'New content update out - 2022.1.2.2',
    date: '2022/03/07 15:00',
    isExpanded: false,
    isCurrent: false,
    data: [
      10255,
      10265,
      10266,
      10270,
      10271,
      10272,
      10273,
      10275,
      10276,
      10278,
      10279,
      10280,
      10281,
      10283,
      10284,
      10289,
      10290,
      10291,
      10292,
      10293,
      10295,
      10297,
      10299,
      10713,
      10714,
      10772,
      10773,
      10774,
      10775,
      10776,
      10781,
      10782,
      10783,
      10784,
      10872,
      10874,
      10875,
      10882,
      10899,
      10909,
      10913,
      10914,
      10915,
      10920,
      10929,
      10930,
      10934,
      10939,
      10940,
      10941,
      10942,
      10943,
      10944,
      10945,
      10946,
      10947,
      10948,
      10950,
      10951,
      10952,
      10953,
      10954,
      10955,
      10956,
      10957,
      10959,
      10960,
      10961,
      10964,
      10965,
      10966,
      10967,
      10968,
      10970,
      10978,
      11006,
      11008,
      11010,
      11011,
      11012,
      11013,
      11014,
      11015,
      11016,
      11017,
      11018,
      11019,
      11717,
      17101,
      21028,
      21034,
      21042,
      21044,
      21051,
      21054,
      21056,
      21057,
      21158,
      21160,
      21161,
      21164,
      21165,
      21166,
      21167,
      21168,
      21169,
      21170,
      21171,
      21172,
      21173,
      21174,
      21176,
      21177,
      21178,
      21179,
      21180,
      21181,
      21183,
      21226,
      21318,
      21319,
      21321,
      21323,
      21324,
      21325,
      21326,
      21327,
      21328,
      21329,
      21330,
      21331,
      21332,
      30329,
      30330,
      30331,
      30332,
      30343,
      30344,
      30366,
      30367,
      30368,
      30370,
      30385,
      30388,
      30391,
      30412,
      30413,
      30463,
      30465,
      30536,
      30537,
      30539,
      30558,
      30563,
      30564,
      30565,
      30566,
      30567,
      30568,
      30576,
      30577,
      30578,
      30579,
      30591,
      31058,
      31088,
      31100,
      31108,
      31109,
      31111,
      31112,
      31113,
      31114,
      31115,
      31116,
      31117,
      31118,
      31119,
      31120,
      31121,
      31122,
      31123,
      31124,
      31125,
      31126,
      31127,
      31128,
      31129,
      31204,
      40373,
      40374,
      40377,
      40378,
      40393,
      40540,
      40543,
      40544,
      40548,
      40549,
      40550,
      40552,
      40553,
      40559,
      41166,
      41168,
      41393,
      41395,
      41410,
      41411,
      41412,
      41413,
      41414,
      41438,
      41441,
      41443,
      41444,
      41448,
      41449,
      41450,
      41677,
      41679,
      41681,
      41682,
      41683,
      41684,
      41685,
      41686,
      41687,
      41688,
      41689,
      41691,
      41693,
      41694,
      41695,
      41697,
      41698,
      41699,
      41700,
      41701,
      41702,
      41703,
      41704,
      41707,
      41709,
      41718,
      41926,
      41935,
      41936,
      41937,
      41942,
      41943,
      41944,
      41945,
      41946,
      41948,
      41949,
      41950,
      41951,
      41952,
      42083,
      42095,
      42096,
      42099,
      42100,
      42107,
      42109,
      42110,
      42111,
      42114,
      42115,
      42116,
      42117,
      42118,
      42119,
      42120,
      42121,
      42122,
      42123,
      42124,
      42125,
      42126,
      42127,
      42128,
      42129,
      42130,
      42131,
      42132,
      42133,
      42134,
      42135,
      42136,
      42137,
      42138,
      42139,
      42140,
      42141,
      43101,
      43102,
      43103,
      43104,
      43105,
      43106,
      43107,
      43108,
      43109,
      43110,
      43111,
      43112,
      43113,
      43114,
      43115,
      43179,
      43187,
      43189,
      43192,
      43193,
      43194,
      43195,
      43196,
      43197,
      43198,
      43199,
      43200,
      43201,
      43202,
      43203,
      43207,
      43208,
      43209,
      51515,
      60197,
      60198,
      60205,
      60243,
      60253,
      60262,
      60265,
      60275,
      60276,
      60277,
      60280,
      60281,
      60282,
      60283,
      60284,
      60287,
      60291,
      60292,
      60293,
      60294,
      60295,
      60296,
      60297,
      60298,
      60299,
      60300,
      60301,
      60302,
      60304,
      60305,
      60307,
      60308,
      60309,
      60310,
      60311,
      60312,
      60314,
      60315,
      60316,
      60317,
      60318,
      60319,
      60320,
      60321,
      60322,
      60323,
      60324,
      60325,
      60326,
      60327,
      60328,
      60329,
      60330,
      60343,
      60348,
      60349,
      60350,
      60351,
      70688,
      70689,
      70690,
      71360,
      71367,
      71374,
      71387,
      71388,
      71389,
      71390,
      71391,
      71392,
      71393,
      71395,
      71396,
      71397,
      71398,
      71399,
      71400,
      71401,
      71734,
      71735,
      71736,
      71737,
      71738,
      71739,
      71740,
      71741,
      71742,
      71745,
      71746,
      71747,
      71748,
      71749,
      71750,
      71752,
      71753,
      71754,
      71755,
      71756,
      71757,
      71760,
      71761,
      71762,
      71763,
      71764,
      71765,
      71766,
      71767,
      71776,
      75192,
      75252,
      75255,
      75257,
      75277,
      75280,
      75283,
      75288,
      75290,
      75292,
      75295,
      75296,
      75297,
      75298,
      75299,
      75300,
      75301,
      75302,
      75304,
      75305,
      75306,
      75308,
      75309,
      75310,
      75311,
      75312,
      75313,
      75314,
      75315,
      75316,
      75319,
      75320,
      75321,
      75322,
      75324,
      75326,
      75327,
      75328,
      75343,
      75546,
      75547,
      75550,
      75948,
      75968,
      75969,
      75979,
      75980,
      76154,
      76155,
      76156,
      76175,
      76178,
      76179,
      76180,
      76181,
      76182,
      76183,
      76184,
      76185,
      76186,
      76187,
      76188,
      76189,
      76190,
      76191,
      76192,
      76193,
      76194,
      76195,
      76198,
      76199,
      76200,
      76201,
      76202,
      76203,
      76205,
      76206,
      76209,
      76237,
      76238,
      76239,
      76240,
      76382,
      76383,
      76385,
      76386,
      76387,
      76388,
      76389,
      76391,
      76392,
      76393,
      76394,
      76395,
      76396,
      76397,
      76398,
      76399,
      76900,
      76901,
      76902,
      76903,
      76904,
      76905,
      76906,
      76907,
      76908,
      76909,
      76910,
      76939,
      76940,
      76941,
      76942,
      80008,
      80012,
      80013,
      80019,
      80020,
      80021,
      80022,
      80023,
      80024,
      80025,
      80026,
      80028,
      80030,
      80031,
      80032,
      80033,
      80034,
      80035,
      80036,
      80108,
      80109,
    ],
  },
  {
    id: '2022.1.1.2',
    title: 'New content update out - 2022.1.1.2',
    date: '2021/12/15 15:00',
    isExpanded: false,
    isCurrent: false,
    data: [
      10255,
      10265,
      10266,
      10270,
      10271,
      10272,
      10273,
      10275,
      10276,
      10278,
      10279,
      10280,
      10281,
      10283,
      10284,
      10289,
      10290,
      10291,
      10292,
      10293,
      10295,
      10297,
      10713,
      10714,
      10772,
      10773,
      10774,
      10775,
      10776,
      10781,
      10782,
      10783,
      10784,
      10872,
      10874,
      10875,
      10882,
      10899,
      10909,
      10913,
      10914,
      10915,
      10920,
      10929,
      10930,
      10934,
      10939,
      10940,
      10941,
      10942,
      10943,
      10944,
      10945,
      10946,
      10947,
      10948,
      10950,
      10951,
      10952,
      10953,
      10954,
      10955,
      10956,
      10957,
      10960,
      10961,
      10964,
      10965,
      10966,
      10978,
      11006,
      11008,
      11010,
      11011,
      11012,
      11013,
      11014,
      11015,
      11016,
      11717,
      17101,
      21028,
      21034,
      21042,
      21044,
      21051,
      21054,
      21056,
      21057,
      21158,
      21160,
      21161,
      21164,
      21165,
      21166,
      21167,
      21168,
      21169,
      21170,
      21171,
      21172,
      21173,
      21174,
      21176,
      21177,
      21178,
      21179,
      21180,
      21181,
      21183,
      21226,
      21318,
      21319,
      21321,
      21323,
      21324,
      21325,
      21326,
      21327,
      21328,
      21329,
      21330,
      30329,
      30330,
      30331,
      30332,
      30343,
      30344,
      30366,
      30367,
      30368,
      30370,
      30385,
      30388,
      30391,
      30412,
      30413,
      30463,
      30465,
      30536,
      30537,
      30539,
      30558,
      30563,
      30564,
      30565,
      30566,
      30567,
      30568,
      30576,
      30577,
      30578,
      30579,
      30591,
      31058,
      31088,
      31100,
      31108,
      31109,
      31111,
      31112,
      31113,
      31114,
      31115,
      31116,
      31117,
      31118,
      31119,
      31120,
      31121,
      31122,
      31129,
      40373,
      40374,
      40377,
      40378,
      40393,
      40540,
      40543,
      40544,
      41166,
      41168,
      41393,
      41395,
      41410,
      41411,
      41412,
      41413,
      41414,
      41438,
      41441,
      41443,
      41444,
      41448,
      41449,
      41450,
      41677,
      41679,
      41681,
      41682,
      41683,
      41684,
      41685,
      41686,
      41687,
      41688,
      41689,
      41691,
      41693,
      41695,
      41697,
      41700,
      41701,
      41702,
      41703,
      41704,
      41707,
      41926,
      41935,
      41936,
      41937,
      41942,
      41943,
      41944,
      41945,
      41946,
      41948,
      42083,
      42095,
      42096,
      42099,
      42100,
      42107,
      42109,
      42110,
      42111,
      42114,
      42115,
      42116,
      42117,
      42118,
      42119,
      42120,
      42121,
      42122,
      42123,
      42124,
      42125,
      42126,
      42127,
      42128,
      42129,
      42130,
      42131,
      42132,
      42134,
      42135,
      42137,
      42138,
      43101,
      43102,
      43103,
      43104,
      43105,
      43106,
      43107,
      43108,
      43109,
      43110,
      43111,
      43112,
      43113,
      43114,
      43115,
      43179,
      43187,
      43189,
      43192,
      43193,
      43194,
      43195,
      43196,
      43197,
      43198,
      43199,
      43200,
      43201,
      43202,
      43203,
      43208,
      51515,
      60197,
      60198,
      60205,
      60243,
      60253,
      60262,
      60265,
      60275,
      60276,
      60277,
      60280,
      60281,
      60282,
      60283,
      60284,
      60287,
      60291,
      60292,
      60293,
      60294,
      60295,
      60296,
      60297,
      60298,
      60299,
      60300,
      60301,
      60302,
      60304,
      60305,
      60307,
      60308,
      60309,
      60310,
      60311,
      60314,
      60315,
      60316,
      60317,
      60318,
      60319,
      60320,
      60321,
      60322,
      60323,
      60324,
      60325,
      60326,
      60327,
      60328,
      60329,
      60330,
      60343,
      60350,
      60351,
      71360,
      71367,
      71374,
      71387,
      71388,
      71389,
      71390,
      71391,
      71392,
      71393,
      71395,
      71396,
      71397,
      71398,
      71399,
      71400,
      71401,
      71734,
      71735,
      71736,
      71737,
      71738,
      71739,
      71740,
      71741,
      71742,
      71745,
      71746,
      71747,
      71748,
      71749,
      71750,
      71752,
      71753,
      71754,
      71755,
      71756,
      71757,
      71760,
      71761,
      71762,
      71763,
      71765,
      71766,
      71767,
      71776,
      75192,
      75252,
      75255,
      75257,
      75277,
      75280,
      75283,
      75288,
      75290,
      75292,
      75295,
      75296,
      75297,
      75298,
      75299,
      75300,
      75301,
      75302,
      75304,
      75305,
      75306,
      75308,
      75309,
      75310,
      75311,
      75312,
      75313,
      75314,
      75315,
      75316,
      75319,
      75320,
      75321,
      75322,
      75546,
      75547,
      75550,
      75948,
      75968,
      75969,
      75979,
      75980,
      76154,
      76155,
      76156,
      76175,
      76178,
      76179,
      76180,
      76181,
      76182,
      76183,
      76184,
      76185,
      76186,
      76187,
      76188,
      76189,
      76190,
      76191,
      76192,
      76193,
      76194,
      76195,
      76198,
      76199,
      76200,
      76201,
      76202,
      76203,
      76204,
      76205,
      76206,
      76237,
      76238,
      76239,
      76240,
      76382,
      76383,
      76385,
      76386,
      76387,
      76388,
      76389,
      76391,
      76392,
      76393,
      76394,
      76395,
      76900,
      76901,
      76902,
      76903,
      76904,
      76905,
      76939,
      76940,
      76941,
      76942,
      80008,
      80012,
      80013,
      80019,
      80020,
      80021,
      80022,
      80023,
      80024,
      80025,
      80026,
      80028,
      80030,
      80031,
      80032,
      80033,
      80034,
      80035,
      80036,
      80108,
      80109,
    ],
  },
  {
    id: '2021.2.3.2',
    title: 'New content update out - 2021.2.3.2',
    date: '2021/10/11 10:00',
    isExpanded: false,
    isCurrent: false,
    data: [
      10255,
      10258,
      10261,
      10264,
      10265,
      10266,
      10270,
      10271,
      10272,
      10273,
      10275,
      10276,
      10277,
      10278,
      10279,
      10280,
      10281,
      10283,
      10284,
      10289,
      10290,
      10291,
      10292,
      10293,
      10295,
      10713,
      10714,
      10715,
      10772,
      10773,
      10774,
      10775,
      10776,
      10840,
      10847,
      10871,
      10872,
      10873,
      10874,
      10875,
      10882,
      10886,
      10894,
      10899,
      10900,
      10901,
      10902,
      10903,
      10906,
      10907,
      10908,
      10909,
      10910,
      10913,
      10914,
      10915,
      10919,
      10920,
      10921,
      10922,
      10924,
      10925,
      10926,
      10927,
      10928,
      10929,
      10930,
      10934,
      10939,
      10940,
      10941,
      10942,
      10943,
      10944,
      10945,
      10946,
      10947,
      10948,
      10950,
      10951,
      10952,
      10953,
      10954,
      10955,
      10956,
      10957,
      10958,
      10961,
      11001,
      11002,
      11006,
      11007,
      11008,
      11009,
      11010,
      11011,
      11012,
      11013,
      11014,
      11015,
      11016,
      11717,
      17101,
      21028,
      21034,
      21042,
      21043,
      21044,
      21045,
      21046,
      21051,
      21052,
      21054,
      21056,
      21151,
      21152,
      21153,
      21154,
      21155,
      21158,
      21159,
      21160,
      21161,
      21162,
      21163,
      21164,
      21165,
      21166,
      21167,
      21168,
      21169,
      21170,
      21171,
      21172,
      21173,
      21174,
      21176,
      21318,
      21319,
      21321,
      21323,
      21324,
      21325,
      21326,
      21327,
      21328,
      21329,
      30329,
      30330,
      30331,
      30332,
      30343,
      30344,
      30366,
      30367,
      30368,
      30370,
      30385,
      30388,
      30391,
      30412,
      30413,
      30463,
      30465,
      30536,
      30537,
      30539,
      30558,
      30563,
      30564,
      30565,
      30566,
      30567,
      30568,
      30576,
      30577,
      30578,
      30579,
      30591,
      31058,
      31088,
      31089,
      31090,
      31093,
      31097,
      31099,
      31100,
      31101,
      31102,
      31104,
      31105,
      31107,
      31108,
      31109,
      31111,
      31112,
      31113,
      31114,
      31115,
      31116,
      31117,
      31118,
      31119,
      31120,
      31121,
      31122,
      31197,
      31198,
      31199,
      40373,
      40374,
      40377,
      40378,
      40393,
      41165,
      41166,
      41167,
      41168,
      41251,
      41252,
      41254,
      41255,
      41258,
      41390,
      41391,
      41392,
      41393,
      41394,
      41395,
      41396,
      41398,
      41410,
      41411,
      41412,
      41413,
      41414,
      41421,
      41422,
      41423,
      41424,
      41425,
      41426,
      41427,
      41428,
      41429,
      41430,
      41431,
      41433,
      41438,
      41440,
      41441,
      41443,
      41444,
      41445,
      41446,
      41447,
      41448,
      41449,
      41450,
      41666,
      41668,
      41669,
      41670,
      41671,
      41677,
      41679,
      41681,
      41682,
      41683,
      41684,
      41685,
      41686,
      41687,
      41688,
      41689,
      41690,
      41691,
      41692,
      41693,
      41906,
      41907,
      41909,
      41910,
      41911,
      41913,
      41914,
      41915,
      41918,
      41919,
      41921,
      41922,
      41923,
      41926,
      41927,
      41928,
      41929,
      41930,
      41931,
      41932,
      41933,
      41934,
      41935,
      41936,
      41937,
      41939,
      41940,
      42083,
      42090,
      42092,
      42093,
      42095,
      42096,
      42099,
      42100,
      42102,
      42103,
      42105,
      42106,
      42107,
      42108,
      42109,
      42110,
      42111,
      42112,
      42114,
      42115,
      42116,
      42117,
      42118,
      42119,
      42120,
      42121,
      42122,
      42123,
      42124,
      42125,
      42126,
      42128,
      42129,
      42131,
      43101,
      43102,
      43103,
      43104,
      43105,
      43106,
      43107,
      43108,
      43109,
      43110,
      43111,
      43112,
      43113,
      43114,
      43115,
      43175,
      43176,
      43177,
      43179,
      43180,
      43181,
      43184,
      43185,
      43186,
      43187,
      43188,
      43189,
      43191,
      43192,
      43193,
      43194,
      43195,
      43196,
      43197,
      51515,
      60139,
      60197,
      60198,
      60205,
      60214,
      60215,
      60216,
      60220,
      60221,
      60226,
      60228,
      60229,
      60239,
      60241,
      60242,
      60243,
      60244,
      60245,
      60246,
      60247,
      60248,
      60249,
      60250,
      60251,
      60252,
      60253,
      60254,
      60256,
      60258,
      60260,
      60261,
      60262,
      60263,
      60264,
      60265,
      60266,
      60267,
      60270,
      60271,
      60272,
      60273,
      60274,
      60275,
      60276,
      60277,
      60279,
      60280,
      60281,
      60282,
      60283,
      60284,
      60285,
      60286,
      60287,
      60288,
      60289,
      60290,
      60291,
      60292,
      60293,
      60294,
      60295,
      60296,
      60297,
      60298,
      60299,
      60300,
      60301,
      60302,
      60303,
      60304,
      60305,
      60306,
      60307,
      60308,
      60309,
      60310,
      60311,
      70685,
      70686,
      70687,
      71029,
      71044,
      71360,
      71362,
      71365,
      71366,
      71367,
      71368,
      71369,
      71370,
      71371,
      71372,
      71373,
      71376,
      71377,
      71380,
      71381,
      71382,
      71383,
      71384,
      71385,
      71392,
      71393,
      71703,
      71704,
      71705,
      71707,
      71708,
      71709,
      71710,
      71711,
      71712,
      71717,
      71718,
      71719,
      71720,
      71721,
      71722,
      71731,
      71732,
      71733,
      71734,
      71735,
      71736,
      71737,
      71738,
      71739,
      71740,
      71741,
      71742,
      71745,
      71746,
      71747,
      71748,
      71749,
      71750,
      71752,
      71753,
      71754,
      71755,
      71756,
      75192,
      75249,
      75252,
      75254,
      75255,
      75256,
      75257,
      75266,
      75267,
      75268,
      75269,
      75270,
      75271,
      75272,
      75273,
      75274,
      75275,
      75276,
      75277,
      75278,
      75280,
      75281,
      75283,
      75284,
      75286,
      75288,
      75290,
      75291,
      75292,
      75293,
      75295,
      75296,
      75297,
      75298,
      75299,
      75300,
      75301,
      75302,
      75304,
      75305,
      75306,
      75307,
      75308,
      75309,
      75310,
      75311,
      75312,
      75314,
      75315,
      75316,
      75319,
      75546,
      75547,
      75549,
      75550,
      75810,
      75890,
      75891,
      75892,
      75893,
      75895,
      75935,
      75936,
      75939,
      75940,
      75941,
      75942,
      75947,
      75948,
      75953,
      75954,
      75957,
      75966,
      75967,
      75968,
      75969,
      75979,
      75980,
      76125,
      76139,
      76140,
      76141,
      76143,
      76146,
      76150,
      76151,
      76152,
      76153,
      76154,
      76155,
      76156,
      76157,
      76158,
      76159,
      76160,
      76162,
      76163,
      76164,
      76165,
      76166,
      76167,
      76168,
      76169,
      76170,
      76171,
      76172,
      76173,
      76174,
      76175,
      76178,
      76180,
      76182,
      76184,
      76185,
      76186,
      76187,
      76188,
      76189,
      76190,
      76191,
      76192,
      76193,
      76194,
      76195,
      76196,
      76198,
      76199,
      76200,
      76201,
      76237,
      76238,
      76239,
      76382,
      76383,
      76384,
      76385,
      76386,
      76387,
      76388,
      76389,
      76390,
      76391,
      76392,
      76393,
      76394,
      76395,
      76895,
      76896,
      76897,
      76898,
      76899,
      76900,
      76901,
      76902,
      76903,
      76904,
      76905,
      76939,
      76940,
      76941,
      76942,
      80006,
      80007,
      80008,
      80009,
      80010,
      80011,
      80012,
      80013,
      80014,
      80015,
      80016,
      80018,
      80019,
      80020,
      80021,
      80022,
      80023,
      80024,
      80025,
      80026,
      80028,
      80106,
      80107,
    ],
  },
  {
    id: '2021.2.2.3',
    title: 'New content update out - 2021.2.2.3',
    date: '2021/07/13 - 17:00',
    isExpanded: false,
    isCurrent: false,
    data: [
      10255,
      10258,
      10261,
      10264,
      10265,
      10266,
      10270,
      10271,
      10272,
      10273,
      10275,
      10276,
      10277,
      10278,
      10280,
      10281,
      10283,
      10289,
      10292,
      10295,
      10713,
      10714,
      10715,
      10772,
      10773,
      10774,
      10775,
      10776,
      10840,
      10847,
      10871,
      10872,
      10873,
      10874,
      10875,
      10882,
      10886,
      10894,
      10899,
      10900,
      10901,
      10902,
      10903,
      10906,
      10907,
      10908,
      10909,
      10910,
      10913,
      10914,
      10915,
      10919,
      10920,
      10921,
      10922,
      10924,
      10925,
      10926,
      10927,
      10928,
      10929,
      10930,
      10934,
      10939,
      10940,
      10941,
      10942,
      10943,
      10944,
      10945,
      10946,
      10947,
      10948,
      10950,
      10951,
      10952,
      10953,
      10954,
      10955,
      10956,
      10957,
      10958,
      10961,
      11001,
      11002,
      11006,
      11007,
      11008,
      11009,
      11010,
      11011,
      11012,
      11013,
      11014,
      11015,
      11016,
      11717,
      17101,
      21028,
      21034,
      21042,
      21043,
      21044,
      21045,
      21046,
      21051,
      21052,
      21054,
      21056,
      21151,
      21152,
      21153,
      21154,
      21155,
      21158,
      21159,
      21160,
      21161,
      21162,
      21163,
      21164,
      21165,
      21166,
      21167,
      21168,
      21169,
      21170,
      21171,
      21172,
      21173,
      21174,
      21176,
      21318,
      21319,
      21321,
      21323,
      21324,
      21325,
      21326,
      21327,
      30329,
      30330,
      30331,
      30332,
      30343,
      30344,
      30366,
      30367,
      30368,
      30370,
      30385,
      30388,
      30391,
      30412,
      30413,
      30463,
      30465,
      30536,
      30537,
      30539,
      30558,
      30563,
      30564,
      30565,
      30566,
      30567,
      30568,
      30576,
      30577,
      30578,
      30579,
      30591,
      31058,
      31088,
      31089,
      31090,
      31093,
      31097,
      31099,
      31100,
      31101,
      31102,
      31104,
      31105,
      31107,
      31108,
      31109,
      31111,
      31112,
      31113,
      31114,
      31115,
      31116,
      31117,
      31118,
      31119,
      31120,
      31121,
      31122,
      31197,
      31198,
      31199,
      40373,
      40374,
      40377,
      40378,
      40393,
      41165,
      41166,
      41167,
      41168,
      41251,
      41252,
      41254,
      41255,
      41258,
      41390,
      41391,
      41392,
      41393,
      41394,
      41395,
      41396,
      41398,
      41410,
      41411,
      41412,
      41413,
      41414,
      41421,
      41422,
      41423,
      41424,
      41425,
      41426,
      41427,
      41428,
      41429,
      41430,
      41431,
      41433,
      41438,
      41440,
      41441,
      41443,
      41444,
      41445,
      41446,
      41447,
      41448,
      41449,
      41450,
      41666,
      41668,
      41669,
      41670,
      41671,
      41677,
      41679,
      41681,
      41682,
      41683,
      41684,
      41685,
      41686,
      41687,
      41688,
      41689,
      41691,
      41692,
      41693,
      41906,
      41907,
      41909,
      41910,
      41911,
      41913,
      41914,
      41915,
      41918,
      41919,
      41921,
      41922,
      41923,
      41926,
      41927,
      41928,
      41929,
      41930,
      41931,
      41932,
      41933,
      41934,
      41935,
      41936,
      41937,
      42083,
      42090,
      42092,
      42093,
      42095,
      42096,
      42099,
      42100,
      42102,
      42103,
      42105,
      42106,
      42107,
      42108,
      42109,
      42110,
      42111,
      42112,
      42114,
      42115,
      42116,
      42117,
      42118,
      42119,
      42120,
      42121,
      42122,
      42123,
      42124,
      42125,
      43101,
      43102,
      43103,
      43104,
      43105,
      43106,
      43107,
      43109,
      43110,
      43111,
      43112,
      43113,
      43114,
      43115,
      43175,
      43176,
      43177,
      43179,
      43180,
      43181,
      43184,
      43185,
      43186,
      43187,
      43188,
      43189,
      43191,
      43192,
      43194,
      43196,
      51515,
      60139,
      60197,
      60198,
      60205,
      60214,
      60215,
      60216,
      60220,
      60221,
      60226,
      60228,
      60229,
      60239,
      60241,
      60242,
      60243,
      60244,
      60245,
      60246,
      60247,
      60248,
      60249,
      60250,
      60251,
      60252,
      60253,
      60254,
      60256,
      60258,
      60260,
      60261,
      60262,
      60263,
      60264,
      60265,
      60266,
      60267,
      60270,
      60271,
      60272,
      60273,
      60274,
      60275,
      60276,
      60277,
      60279,
      60280,
      60281,
      60282,
      60283,
      60284,
      60285,
      60286,
      60287,
      60288,
      60289,
      60290,
      60291,
      60292,
      60300,
      60301,
      60302,
      60304,
      60305,
      60306,
      60307,
      60308,
      70685,
      70686,
      70687,
      71029,
      71044,
      71360,
      71362,
      71365,
      71366,
      71367,
      71368,
      71369,
      71370,
      71371,
      71372,
      71373,
      71376,
      71377,
      71380,
      71381,
      71382,
      71383,
      71384,
      71385,
      71703,
      71704,
      71705,
      71707,
      71708,
      71709,
      71710,
      71711,
      71712,
      71717,
      71718,
      71719,
      71720,
      71721,
      71722,
      71731,
      71732,
      71733,
      71734,
      71735,
      71736,
      71737,
      71738,
      71739,
      71740,
      71741,
      71742,
      71745,
      71746,
      71747,
      71748,
      71749,
      71750,
      71752,
      71753,
      71754,
      71755,
      71756,
      75192,
      75249,
      75252,
      75254,
      75255,
      75256,
      75257,
      75266,
      75267,
      75268,
      75269,
      75270,
      75271,
      75272,
      75273,
      75274,
      75275,
      75276,
      75277,
      75278,
      75280,
      75281,
      75283,
      75284,
      75286,
      75288,
      75290,
      75291,
      75292,
      75293,
      75295,
      75297,
      75298,
      75299,
      75300,
      75301,
      75302,
      75304,
      75305,
      75306,
      75308,
      75546,
      75547,
      75549,
      75550,
      75810,
      75890,
      75891,
      75892,
      75893,
      75895,
      75935,
      75936,
      75939,
      75940,
      75941,
      75942,
      75947,
      75948,
      75953,
      75954,
      75957,
      75966,
      75967,
      75968,
      75969,
      75979,
      75980,
      76125,
      76139,
      76140,
      76141,
      76143,
      76146,
      76150,
      76151,
      76152,
      76153,
      76157,
      76158,
      76159,
      76160,
      76162,
      76163,
      76164,
      76165,
      76166,
      76167,
      76168,
      76169,
      76170,
      76171,
      76172,
      76173,
      76174,
      76175,
      76180,
      76182,
      76186,
      76187,
      76188,
      76189,
      76191,
      76192,
      76193,
      76198,
      76199,
      76382,
      76383,
      76384,
      76385,
      76386,
      76387,
      76388,
      76389,
      76392,
      76393,
      76394,
      76395,
      76895,
      76896,
      76897,
      76898,
      76899,
      76900,
      76901,
      76902,
      76903,
      76904,
      76905,
      80006,
      80007,
      80008,
      80009,
      80010,
      80011,
      80012,
      80013,
      80014,
      80015,
      80016,
      80018,
      80019,
      80020,
      80021,
      80022,
      80023,
      80024,
      80106,
      80107,
    ],
  },
  {
    id: '2021.1.3.1',
    title: 'New content update out - 2021.1.3.1',
    date: '2021/04/09 - 09:04',
    isExpanded: false,
    isCurrent: false,
    data: [
      10255,
      10258,
      10261,
      10264,
      10265,
      10266,
      10270,
      10271,
      10272,
      10273,
      10275,
      10277,
      10278,
      10280,
      10281,
      10283,
      10295,
      10713,
      10714,
      10715,
      10840,
      10847,
      10871,
      10872,
      10873,
      10874,
      10875,
      10882,
      10886,
      10894,
      10899,
      10900,
      10901,
      10902,
      10903,
      10906,
      10907,
      10908,
      10909,
      10910,
      10913,
      10914,
      10915,
      10919,
      10920,
      10921,
      10922,
      10924,
      10925,
      10926,
      10927,
      10928,
      10929,
      10930,
      10934,
      10941,
      10943,
      10950,
      10951,
      10952,
      10953,
      10955,
      10957,
      10958,
      11001,
      11002,
      11006,
      11007,
      11008,
      11009,
      11010,
      11011,
      11012,
      11013,
      11014,
      11016,
      11717,
      17101,
      21028,
      21034,
      21042,
      21043,
      21044,
      21045,
      21046,
      21051,
      21052,
      21054,
      21151,
      21152,
      21153,
      21154,
      21155,
      21158,
      21159,
      21160,
      21161,
      21162,
      21163,
      21164,
      21165,
      21166,
      21167,
      21168,
      21169,
      21170,
      21318,
      21319,
      21321,
      21323,
      21324,
      21325,
      21326,
      30329,
      30330,
      30331,
      30332,
      30343,
      30344,
      30366,
      30367,
      30368,
      30370,
      30385,
      30388,
      30391,
      30412,
      30413,
      30463,
      30465,
      30536,
      30537,
      30539,
      30558,
      30563,
      30564,
      30565,
      30566,
      30567,
      30568,
      30576,
      30577,
      30578,
      30579,
      30591,
      31058,
      31088,
      31089,
      31090,
      31093,
      31097,
      31099,
      31100,
      31101,
      31102,
      31104,
      31105,
      31107,
      31108,
      31109,
      31111,
      31112,
      31113,
      31114,
      31115,
      31116,
      31118,
      31197,
      31198,
      31199,
      40373,
      40374,
      40377,
      40378,
      40393,
      41165,
      41166,
      41167,
      41168,
      41251,
      41252,
      41254,
      41255,
      41258,
      41390,
      41391,
      41392,
      41393,
      41394,
      41395,
      41396,
      41398,
      41410,
      41411,
      41412,
      41413,
      41414,
      41421,
      41422,
      41423,
      41424,
      41425,
      41426,
      41427,
      41428,
      41429,
      41430,
      41431,
      41433,
      41438,
      41440,
      41443,
      41444,
      41445,
      41446,
      41447,
      41448,
      41449,
      41450,
      41666,
      41691,
      41692,
      41906,
      41907,
      41909,
      41910,
      41911,
      41913,
      41914,
      41915,
      41918,
      41919,
      41921,
      41922,
      41923,
      41926,
      41927,
      41928,
      41929,
      41930,
      41931,
      41933,
      41934,
      42083,
      42090,
      42092,
      42093,
      42095,
      42096,
      42099,
      42100,
      42102,
      42103,
      42105,
      42106,
      42107,
      42108,
      42109,
      42110,
      42111,
      42112,
      42114,
      42115,
      42116,
      42117,
      42118,
      42119,
      42120,
      42121,
      42122,
      42123,
      42124,
      42125,
      43101,
      43102,
      43103,
      43104,
      43105,
      43106,
      43107,
      43175,
      43176,
      43177,
      43179,
      43180,
      43181,
      43184,
      43185,
      43186,
      43187,
      43188,
      43189,
      43191,
      43192,
      51515,
      60139,
      60197,
      60198,
      60205,
      60214,
      60215,
      60216,
      60220,
      60221,
      60226,
      60228,
      60229,
      60239,
      60241,
      60242,
      60243,
      60244,
      60245,
      60246,
      60247,
      60248,
      60249,
      60250,
      60251,
      60252,
      60253,
      60254,
      60256,
      60258,
      60260,
      60261,
      60262,
      60263,
      60264,
      60265,
      60266,
      60267,
      60270,
      60271,
      60272,
      60273,
      60274,
      60275,
      60276,
      60277,
      60279,
      60280,
      60281,
      60282,
      60283,
      60284,
      60285,
      60286,
      60287,
      60288,
      60289,
      60290,
      60291,
      60292,
      60304,
      60305,
      60306,
      70685,
      70686,
      70687,
      71029,
      71044,
      71360,
      71362,
      71365,
      71366,
      71367,
      71368,
      71369,
      71370,
      71371,
      71372,
      71373,
      71376,
      71377,
      71380,
      71381,
      71382,
      71383,
      71384,
      71385,
      71703,
      71704,
      71705,
      71707,
      71708,
      71709,
      71710,
      71711,
      71712,
      71717,
      71718,
      71719,
      71720,
      71721,
      71722,
      71731,
      71732,
      71733,
      71735,
      71736,
      71737,
      71738,
      71740,
      71741,
      71742,
      71745,
      71746,
      71747,
      71748,
      75192,
      75249,
      75252,
      75254,
      75255,
      75256,
      75257,
      75266,
      75267,
      75268,
      75269,
      75270,
      75271,
      75272,
      75273,
      75274,
      75275,
      75276,
      75277,
      75278,
      75280,
      75281,
      75283,
      75284,
      75286,
      75288,
      75290,
      75291,
      75292,
      75293,
      75295,
      75297,
      75298,
      75299,
      75300,
      75301,
      75302,
      75546,
      75549,
      75550,
      75810,
      75890,
      75891,
      75892,
      75893,
      75895,
      75935,
      75936,
      75939,
      75940,
      75941,
      75942,
      75947,
      75948,
      75953,
      75954,
      75957,
      75966,
      75967,
      75968,
      75969,
      75979,
      75980,
      76125,
      76139,
      76140,
      76141,
      76143,
      76146,
      76150,
      76151,
      76152,
      76153,
      76157,
      76158,
      76159,
      76160,
      76162,
      76163,
      76164,
      76165,
      76166,
      76167,
      76168,
      76169,
      76170,
      76171,
      76172,
      76173,
      76174,
      76175,
      76382,
      76383,
      76384,
      76385,
      76895,
      76896,
      76897,
      76898,
      76899,
      80006,
      80007,
      80008,
      80009,
      80010,
      80011,
      80012,
      80013,
      80014,
      80015,
      80016,
      80018,
      80019,
      80020,
      80021,
      80022,
      80023,
      80024,
      80106,
      80107,
    ],
  },
  {
    id: '2021.1.2.3',
    title: 'New content update out - 2021.1.2.3',
    date: '2021/03/03 - 13:03',
    isExpanded: false,
    isCurrent: false,
    data: [
      10255,
      10258,
      10261,
      10264,
      10265,
      10266,
      10270,
      10271,
      10272,
      10273,
      10275,
      10277,
      10278,
      10280,
      10281,
      10295,
      10713,
      10714,
      10715,
      10840,
      10847,
      10871,
      10872,
      10873,
      10874,
      10875,
      10882,
      10886,
      10894,
      10899,
      10900,
      10901,
      10902,
      10903,
      10906,
      10907,
      10908,
      10909,
      10910,
      10913,
      10914,
      10915,
      10919,
      10920,
      10921,
      10922,
      10924,
      10925,
      10926,
      10927,
      10928,
      10929,
      10930,
      10934,
      10941,
      10943,
      10950,
      10951,
      10952,
      10953,
      10955,
      10957,
      10958,
      11001,
      11002,
      11006,
      11007,
      11008,
      11009,
      11010,
      11011,
      11012,
      11013,
      11014,
      11016,
      11717,
      17101,
      21028,
      21034,
      21042,
      21043,
      21044,
      21045,
      21046,
      21051,
      21052,
      21054,
      21151,
      21152,
      21153,
      21154,
      21155,
      21158,
      21159,
      21160,
      21161,
      21162,
      21163,
      21164,
      21165,
      21166,
      21167,
      21168,
      21169,
      21170,
      21318,
      21319,
      21321,
      21323,
      21324,
      21325,
      30329,
      30330,
      30331,
      30332,
      30343,
      30344,
      30366,
      30367,
      30368,
      30370,
      30385,
      30388,
      30391,
      30412,
      30413,
      30463,
      30465,
      30536,
      30537,
      30539,
      30558,
      30563,
      30564,
      30565,
      30566,
      30567,
      30568,
      30576,
      30577,
      30578,
      30579,
      30591,
      31058,
      31088,
      31089,
      31090,
      31093,
      31097,
      31099,
      31100,
      31101,
      31102,
      31104,
      31105,
      31107,
      31108,
      31109,
      31111,
      31112,
      31113,
      31114,
      31115,
      31116,
      31118,
      31197,
      31198,
      31199,
      40373,
      40374,
      40377,
      40378,
      40393,
      41165,
      41166,
      41167,
      41168,
      41251,
      41252,
      41254,
      41255,
      41258,
      41390,
      41391,
      41392,
      41393,
      41394,
      41395,
      41396,
      41398,
      41410,
      41411,
      41412,
      41413,
      41414,
      41421,
      41422,
      41423,
      41424,
      41425,
      41426,
      41427,
      41428,
      41429,
      41430,
      41431,
      41433,
      41438,
      41440,
      41443,
      41444,
      41445,
      41446,
      41447,
      41448,
      41449,
      41450,
      41666,
      41691,
      41692,
      41906,
      41907,
      41909,
      41910,
      41911,
      41913,
      41914,
      41915,
      41918,
      41919,
      41921,
      41922,
      41923,
      41926,
      41927,
      41928,
      41929,
      41930,
      41931,
      41933,
      41934,
      42083,
      42090,
      42092,
      42093,
      42095,
      42096,
      42099,
      42100,
      42102,
      42103,
      42105,
      42106,
      42107,
      42108,
      42109,
      42110,
      42111,
      42112,
      42114,
      42115,
      42116,
      42117,
      42118,
      42119,
      42120,
      42121,
      42122,
      42123,
      42124,
      42125,
      43101,
      43102,
      43103,
      43104,
      43105,
      43106,
      43107,
      43175,
      43176,
      43177,
      43179,
      43180,
      43181,
      43184,
      43185,
      43186,
      43187,
      43188,
      43189,
      43191,
      43192,
      51515,
      60139,
      60197,
      60198,
      60205,
      60214,
      60215,
      60216,
      60220,
      60221,
      60226,
      60228,
      60229,
      60239,
      60241,
      60242,
      60243,
      60244,
      60245,
      60246,
      60247,
      60248,
      60249,
      60250,
      60251,
      60252,
      60253,
      60254,
      60256,
      60258,
      60260,
      60261,
      60262,
      60263,
      60264,
      60265,
      60266,
      60267,
      60270,
      60271,
      60272,
      60273,
      60274,
      60275,
      60276,
      60277,
      60279,
      60280,
      60281,
      60282,
      60283,
      60284,
      60285,
      60286,
      60287,
      60288,
      60289,
      60290,
      60291,
      60292,
      60304,
      60305,
      60306,
      70685,
      70686,
      70687,
      71029,
      71044,
      71360,
      71362,
      71365,
      71366,
      71367,
      71368,
      71369,
      71370,
      71371,
      71372,
      71373,
      71376,
      71377,
      71380,
      71381,
      71382,
      71383,
      71384,
      71385,
      71703,
      71704,
      71705,
      71707,
      71708,
      71709,
      71710,
      71711,
      71712,
      71717,
      71718,
      71719,
      71720,
      71721,
      71722,
      71731,
      71732,
      71733,
      71735,
      71736,
      71737,
      71738,
      71740,
      71741,
      71742,
      71745,
      71746,
      71747,
      71748,
      75192,
      75249,
      75252,
      75254,
      75255,
      75256,
      75257,
      75266,
      75267,
      75268,
      75269,
      75270,
      75271,
      75272,
      75273,
      75274,
      75275,
      75276,
      75277,
      75278,
      75280,
      75281,
      75283,
      75284,
      75286,
      75288,
      75290,
      75291,
      75292,
      75293,
      75295,
      75299,
      75300,
      75301,
      75546,
      75549,
      75550,
      75810,
      75890,
      75891,
      75892,
      75893,
      75895,
      75935,
      75936,
      75939,
      75940,
      75941,
      75942,
      75947,
      75948,
      75953,
      75954,
      75957,
      75966,
      75967,
      75968,
      75969,
      75979,
      75980,
      76125,
      76139,
      76140,
      76141,
      76143,
      76146,
      76150,
      76151,
      76152,
      76153,
      76157,
      76158,
      76159,
      76160,
      76162,
      76163,
      76164,
      76165,
      76166,
      76167,
      76168,
      76169,
      76170,
      76171,
      76172,
      76173,
      76174,
      76175,
      76382,
      76383,
      76384,
      76385,
      76895,
      76896,
      76897,
      76898,
      76899,
      80006,
      80007,
      80008,
      80009,
      80010,
      80011,
      80012,
      80013,
      80014,
      80015,
      80016,
      80018,
      80019,
      80020,
      80021,
      80022,
      80023,
      80024,
      80106,
      80107,
    ],
  },
  {
    id: '2021.1.1.6',
    title: 'New content update out - 2021.1.1.6',
    date: '2021/01/06 - 17:01',
    isExpanded: false,
    isCurrent: false,
    data: [
      10255,
      10258,
      10261,
      10264,
      10265,
      10266,
      10270,
      10271,
      10272,
      10273,
      10275,
      10276,
      10277,
      10278,
      10280,
      10281,
      10713,
      10714,
      10715,
      10840,
      10847,
      10871,
      10872,
      10873,
      10874,
      10875,
      10882,
      10886,
      10894,
      10899,
      10900,
      10901,
      10902,
      10903,
      10906,
      10907,
      10908,
      10909,
      10910,
      10913,
      10914,
      10915,
      10919,
      10920,
      10921,
      10922,
      10924,
      10925,
      10926,
      10927,
      10928,
      10929,
      10930,
      10934,
      10941,
      10943,
      10953,
      10955,
      10957,
      10958,
      11001,
      11002,
      11006,
      11007,
      11008,
      11009,
      11010,
      11011,
      11013,
      11016,
      11717,
      17101,
      21028,
      21034,
      21042,
      21043,
      21044,
      21045,
      21046,
      21051,
      21052,
      21054,
      21151,
      21152,
      21153,
      21154,
      21155,
      21158,
      21159,
      21160,
      21161,
      21162,
      21163,
      21164,
      21165,
      21166,
      21167,
      21170,
      21318,
      21319,
      21321,
      21323,
      21324,
      30264,
      30329,
      30330,
      30331,
      30332,
      30343,
      30366,
      30367,
      30368,
      30370,
      30385,
      30388,
      30391,
      30412,
      30413,
      30463,
      30465,
      30536,
      30537,
      30539,
      30563,
      30564,
      30565,
      30566,
      30567,
      30568,
      30576,
      30577,
      30578,
      30591,
      31058,
      31088,
      31089,
      31090,
      31093,
      31097,
      31099,
      31100,
      31101,
      31102,
      31104,
      31105,
      31107,
      31108,
      31109,
      31111,
      31112,
      31113,
      31114,
      31118,
      31197,
      31198,
      31199,
      31201,
      40373,
      40374,
      40377,
      40378,
      40393,
      41165,
      41166,
      41167,
      41168,
      41251,
      41252,
      41254,
      41255,
      41258,
      41390,
      41391,
      41392,
      41393,
      41394,
      41395,
      41396,
      41398,
      41410,
      41411,
      41412,
      41413,
      41414,
      41421,
      41422,
      41423,
      41424,
      41425,
      41426,
      41427,
      41428,
      41429,
      41430,
      41431,
      41433,
      41438,
      41444,
      41447,
      41448,
      41449,
      41666,
      41691,
      41692,
      41906,
      41907,
      41909,
      41910,
      41911,
      41913,
      41914,
      41915,
      41918,
      41919,
      41921,
      41923,
      41926,
      41934,
      42083,
      42090,
      42092,
      42093,
      42095,
      42096,
      42099,
      42100,
      42102,
      42103,
      42105,
      42106,
      42107,
      42108,
      42109,
      42110,
      42111,
      42112,
      42114,
      42115,
      42116,
      42117,
      42118,
      42119,
      42122,
      42123,
      42124,
      42125,
      43175,
      43176,
      43177,
      43179,
      43180,
      43186,
      43187,
      43188,
      43189,
      43191,
      43192,
      51515,
      60139,
      60197,
      60198,
      60205,
      60214,
      60215,
      60216,
      60220,
      60221,
      60226,
      60228,
      60229,
      60239,
      60241,
      60242,
      60243,
      60244,
      60245,
      60246,
      60247,
      60248,
      60249,
      60250,
      60251,
      60252,
      60253,
      60254,
      60256,
      60258,
      60260,
      60261,
      60262,
      60263,
      60264,
      60265,
      60266,
      60267,
      60270,
      60271,
      60272,
      60273,
      60274,
      60275,
      60276,
      60277,
      60279,
      60280,
      60281,
      60282,
      60283,
      60284,
      60285,
      60286,
      60287,
      60288,
      60289,
      60290,
      60291,
      60292,
      60304,
      60305,
      60306,
      70685,
      70686,
      70687,
      71044,
      71360,
      71362,
      71365,
      71366,
      71367,
      71368,
      71369,
      71370,
      71371,
      71372,
      71373,
      71376,
      71377,
      71380,
      71381,
      71382,
      71383,
      71384,
      71385,
      71386,
      71703,
      71704,
      71705,
      71707,
      71708,
      71709,
      71710,
      71711,
      71712,
      71717,
      71718,
      71719,
      71720,
      71721,
      71722,
      71731,
      71732,
      71733,
      71735,
      71736,
      71737,
      71738,
      71741,
      71742,
      75192,
      75249,
      75252,
      75254,
      75255,
      75256,
      75257,
      75266,
      75267,
      75268,
      75269,
      75270,
      75271,
      75272,
      75273,
      75274,
      75275,
      75276,
      75277,
      75278,
      75280,
      75281,
      75283,
      75284,
      75286,
      75288,
      75290,
      75291,
      75292,
      75293,
      75295,
      75300,
      75301,
      75546,
      75549,
      75550,
      75810,
      75890,
      75891,
      75892,
      75893,
      75895,
      75935,
      75936,
      75939,
      75940,
      75941,
      75942,
      75947,
      75948,
      75953,
      75954,
      75957,
      75966,
      75967,
      75968,
      75969,
      75979,
      75980,
      76125,
      76139,
      76140,
      76141,
      76143,
      76146,
      76150,
      76151,
      76152,
      76153,
      76157,
      76158,
      76159,
      76160,
      76162,
      76163,
      76164,
      76165,
      76166,
      76167,
      76168,
      76169,
      76170,
      76171,
      76172,
      76173,
      76174,
      76382,
      76383,
      76384,
      76385,
      76895,
      76896,
      76897,
      76898,
      76899,
      80006,
      80007,
      80008,
      80009,
      80010,
      80011,
      80012,
      80013,
      80014,
      80015,
      80016,
      80023,
      80106,
      80107,
    ],
  },
  {
    id: '2020.2.3.6',
    title: 'New content update out - 2020.2.3.6',
    date: '2020/10/12 - 11:10',
    isExpanded: false,
    isCurrent: false,
    data: [
      10252,
      10255,
      10256,
      10258,
      10260,
      10261,
      10264,
      10265,
      10266,
      10268,
      10270,
      10271,
      10272,
      10273,
      10275,
      10277,
      10572,
      10597,
      10713,
      10714,
      10715,
      10766,
      10768,
      10769,
      10770,
      10771,
      10812,
      10840,
      10847,
      10863,
      10870,
      10871,
      10872,
      10873,
      10874,
      10875,
      10882,
      10883,
      10884,
      10885,
      10886,
      10889,
      10894,
      10897,
      10899,
      10900,
      10901,
      10902,
      10903,
      10904,
      10906,
      10907,
      10908,
      10909,
      10910,
      10913,
      10914,
      10915,
      10917,
      10918,
      10919,
      10920,
      10921,
      10922,
      10924,
      10925,
      10926,
      10927,
      10928,
      10929,
      10930,
      10934,
      11001,
      11002,
      11003,
      11004,
      11006,
      11007,
      11008,
      11009,
      11010,
      11011,
      11717,
      17101,
      21028,
      21034,
      21039,
      21042,
      21043,
      21044,
      21045,
      21046,
      21047,
      21051,
      21052,
      21054,
      21140,
      21141,
      21143,
      21145,
      21151,
      21152,
      21153,
      21154,
      21155,
      21156,
      21157,
      21158,
      21159,
      21160,
      21161,
      21162,
      21163,
      21316,
      21317,
      21318,
      21319,
      21321,
      21323,
      30329,
      30330,
      30366,
      30367,
      30368,
      30369,
      30370,
      30385,
      30412,
      30463,
      30464,
      30536,
      30537,
      30574,
      30576,
      31058,
      31086,
      31087,
      31088,
      31089,
      31090,
      31091,
      31092,
      31093,
      31094,
      31095,
      31096,
      31097,
      31098,
      31099,
      31100,
      31101,
      31102,
      31103,
      31104,
      31105,
      31107,
      31108,
      31109,
      31197,
      31198,
      31199,
      40372,
      40373,
      40374,
      40377,
      40378,
      40393,
      41164,
      41165,
      41166,
      41167,
      41168,
      41250,
      41251,
      41252,
      41253,
      41254,
      41255,
      41256,
      41258,
      41335,
      41337,
      41360,
      41361,
      41362,
      41366,
      41367,
      41369,
      41371,
      41372,
      41373,
      41374,
      41375,
      41376,
      41378,
      41379,
      41380,
      41381,
      41389,
      41390,
      41391,
      41392,
      41393,
      41394,
      41395,
      41396,
      41397,
      41398,
      41410,
      41411,
      41412,
      41413,
      41414,
      41420,
      41421,
      41422,
      41423,
      41424,
      41425,
      41426,
      41427,
      41428,
      41429,
      41430,
      41431,
      41433,
      41434,
      41435,
      41436,
      41437,
      41438,
      41900,
      41901,
      41902,
      41903,
      41904,
      41905,
      41906,
      41907,
      41908,
      41911,
      41912,
      41913,
      41914,
      41915,
      41916,
      41917,
      41919,
      42082,
      42083,
      42088,
      42090,
      42091,
      42092,
      42093,
      42095,
      42096,
      42098,
      42099,
      42100,
      42101,
      42102,
      42103,
      42104,
      42105,
      42106,
      42107,
      42108,
      42109,
      42110,
      42111,
      42112,
      42114,
      42115,
      43170,
      43173,
      43175,
      43176,
      43177,
      43178,
      43179,
      43180,
      43182,
      43183,
      43187,
      43188,
      51515,
      60139,
      60197,
      60198,
      60203,
      60205,
      60206,
      60207,
      60208,
      60210,
      60212,
      60213,
      60214,
      60215,
      60216,
      60217,
      60219,
      60220,
      60221,
      60222,
      60224,
      60225,
      60226,
      60227,
      60228,
      60229,
      60230,
      60231,
      60232,
      60233,
      60234,
      60239,
      60241,
      60242,
      60243,
      60244,
      60245,
      60246,
      60247,
      60248,
      60249,
      60250,
      60251,
      60252,
      60253,
      60254,
      60255,
      60256,
      60257,
      60258,
      60260,
      60261,
      60262,
      60263,
      60264,
      60265,
      60266,
      60267,
      60268,
      60270,
      60271,
      60272,
      60273,
      60274,
      70418,
      70419,
      70420,
      70421,
      70422,
      70423,
      70424,
      70425,
      70427,
      70428,
      70429,
      70430,
      70431,
      70432,
      70433,
      70434,
      70435,
      70436,
      70437,
      70665,
      70666,
      70668,
      70670,
      70671,
      70672,
      70673,
      70674,
      70675,
      70676,
      70677,
      70679,
      70685,
      70686,
      70687,
      71044,
      71360,
      71362,
      71365,
      71366,
      71367,
      71368,
      71369,
      71370,
      71371,
      71372,
      71373,
      71376,
      71377,
      71699,
      71703,
      71704,
      71705,
      71707,
      71708,
      71709,
      71710,
      71711,
      71712,
      71713,
      71714,
      71715,
      71716,
      71717,
      71718,
      71719,
      71720,
      71721,
      71722,
      75159,
      75192,
      75214,
      75234,
      75235,
      75240,
      75241,
      75242,
      75243,
      75244,
      75246,
      75248,
      75249,
      75252,
      75253,
      75254,
      75255,
      75256,
      75257,
      75258,
      75263,
      75264,
      75265,
      75266,
      75267,
      75268,
      75269,
      75270,
      75271,
      75272,
      75273,
      75274,
      75275,
      75276,
      75277,
      75278,
      75279,
      75280,
      75281,
      75283,
      75284,
      75286,
      75288,
      75290,
      75291,
      75292,
      75293,
      75294,
      75546,
      75549,
      75550,
      75810,
      75890,
      75891,
      75892,
      75893,
      75894,
      75895,
      75934,
      75935,
      75936,
      75937,
      75938,
      75939,
      75940,
      75941,
      75942,
      75945,
      75946,
      75947,
      75948,
      75953,
      75954,
      75957,
      75958,
      75965,
      75966,
      75967,
      75968,
      75969,
      75979,
      75980,
      75981,
      76113,
      76114,
      76115,
      76118,
      76119,
      76120,
      76122,
      76123,
      76124,
      76125,
      76126,
      76127,
      76128,
      76130,
      76131,
      76137,
      76138,
      76139,
      76140,
      76141,
      76142,
      76143,
      76144,
      76146,
      76147,
      76148,
      76149,
      76150,
      76151,
      76152,
      76153,
      76157,
      76158,
      76159,
      76160,
      76162,
      76163,
      76164,
      76165,
      76166,
      76167,
      76895,
      76896,
      76897,
      76898,
      76899,
      80006,
      80007,
      80008,
      80009,
      80010,
      80011,
      80012,
      80013,
      80014,
      80015,
      80016,
    ],
  },
  {
    id: '2020.2.2.7',
    title: 'The latest update 2020.2.2.7 is installed',
    date: '2020/09/03 - 16:09',
    isExpanded: false,
    isCurrent: false,
    data: [
      10252,
      10255,
      10256,
      10258,
      10260,
      10261,
      10264,
      10265,
      10266,
      10268,
      10270,
      10271,
      10272,
      10273,
      10277,
      10572,
      10597,
      10713,
      10714,
      10715,
      10766,
      10768,
      10769,
      10770,
      10771,
      10812,
      10840,
      10847,
      10863,
      10870,
      10871,
      10872,
      10873,
      10874,
      10875,
      10882,
      10883,
      10884,
      10885,
      10886,
      10889,
      10894,
      10897,
      10899,
      10900,
      10901,
      10902,
      10903,
      10904,
      10906,
      10907,
      10908,
      10909,
      10910,
      10913,
      10914,
      10915,
      10917,
      10918,
      10920,
      10921,
      10922,
      10924,
      10925,
      10926,
      10927,
      10928,
      10929,
      10930,
      10934,
      11001,
      11002,
      11003,
      11004,
      11006,
      11007,
      11008,
      11009,
      11010,
      11011,
      11717,
      17101,
      21028,
      21034,
      21039,
      21042,
      21043,
      21044,
      21045,
      21046,
      21047,
      21051,
      21052,
      21054,
      21140,
      21141,
      21143,
      21145,
      21151,
      21152,
      21153,
      21154,
      21155,
      21156,
      21157,
      21158,
      21159,
      21160,
      21161,
      21162,
      21163,
      21316,
      21317,
      21318,
      21319,
      21321,
      30329,
      30330,
      30366,
      30367,
      30368,
      30369,
      30370,
      30385,
      30412,
      30463,
      30464,
      30536,
      30537,
      30548,
      30574,
      30576,
      31058,
      31086,
      31087,
      31088,
      31089,
      31090,
      31091,
      31092,
      31093,
      31094,
      31095,
      31096,
      31097,
      31098,
      31099,
      31100,
      31101,
      31102,
      31103,
      31104,
      31105,
      31107,
      31108,
      31109,
      31197,
      31198,
      31199,
      31200,
      40355,
      40372,
      40373,
      40374,
      40377,
      40378,
      40393,
      41164,
      41165,
      41166,
      41167,
      41168,
      41250,
      41251,
      41252,
      41253,
      41254,
      41255,
      41256,
      41258,
      41335,
      41337,
      41360,
      41361,
      41362,
      41366,
      41367,
      41369,
      41371,
      41372,
      41373,
      41374,
      41375,
      41376,
      41378,
      41379,
      41380,
      41381,
      41389,
      41390,
      41391,
      41392,
      41393,
      41394,
      41395,
      41396,
      41397,
      41398,
      41410,
      41411,
      41412,
      41413,
      41414,
      41420,
      41421,
      41422,
      41423,
      41424,
      41425,
      41426,
      41427,
      41428,
      41429,
      41430,
      41431,
      41433,
      41434,
      41435,
      41436,
      41437,
      41438,
      41900,
      41901,
      41902,
      41903,
      41904,
      41905,
      41906,
      41907,
      41908,
      41911,
      41912,
      41913,
      41914,
      41915,
      41916,
      41917,
      41919,
      42082,
      42083,
      42088,
      42090,
      42091,
      42092,
      42093,
      42095,
      42096,
      42098,
      42099,
      42100,
      42101,
      42102,
      42103,
      42104,
      42105,
      42106,
      42107,
      42108,
      42109,
      42110,
      42111,
      42112,
      42114,
      42115,
      43170,
      43173,
      43174,
      43175,
      43176,
      43177,
      43178,
      43179,
      43180,
      43182,
      43183,
      43187,
      43188,
      60139,
      60197,
      60198,
      60203,
      60205,
      60206,
      60207,
      60208,
      60210,
      60212,
      60213,
      60214,
      60215,
      60216,
      60217,
      60219,
      60220,
      60221,
      60222,
      60224,
      60225,
      60226,
      60227,
      60228,
      60229,
      60230,
      60231,
      60232,
      60233,
      60234,
      60238,
      60239,
      60241,
      60242,
      60243,
      60244,
      60245,
      60246,
      60247,
      60248,
      60249,
      60250,
      60251,
      60252,
      60253,
      60254,
      60255,
      60256,
      60257,
      60258,
      60260,
      60261,
      60262,
      60263,
      60264,
      60265,
      60266,
      60267,
      60268,
      60270,
      60271,
      60272,
      60273,
      60274,
      70418,
      70419,
      70420,
      70421,
      70422,
      70423,
      70424,
      70425,
      70427,
      70428,
      70429,
      70430,
      70431,
      70432,
      70433,
      70434,
      70435,
      70436,
      70437,
      70665,
      70666,
      70668,
      70670,
      70671,
      70672,
      70673,
      70674,
      70675,
      70676,
      70677,
      70679,
      70685,
      70686,
      70687,
      71044,
      71360,
      71362,
      71365,
      71366,
      71367,
      71368,
      71369,
      71370,
      71371,
      71372,
      71373,
      71376,
      71377,
      71699,
      71703,
      71704,
      71705,
      71707,
      71708,
      71709,
      71710,
      71711,
      71712,
      71713,
      71714,
      71715,
      71716,
      71717,
      71718,
      71719,
      71720,
      71721,
      71722,
      75159,
      75192,
      75214,
      75234,
      75235,
      75240,
      75241,
      75242,
      75243,
      75244,
      75246,
      75248,
      75249,
      75252,
      75253,
      75254,
      75255,
      75256,
      75257,
      75258,
      75263,
      75264,
      75265,
      75266,
      75267,
      75268,
      75269,
      75270,
      75271,
      75272,
      75273,
      75274,
      75275,
      75276,
      75277,
      75278,
      75279,
      75280,
      75281,
      75283,
      75284,
      75286,
      75288,
      75291,
      75292,
      75293,
      75294,
      75546,
      75549,
      75550,
      75810,
      75890,
      75891,
      75892,
      75893,
      75894,
      75895,
      75934,
      75935,
      75936,
      75937,
      75938,
      75939,
      75940,
      75941,
      75942,
      75945,
      75946,
      75947,
      75948,
      75953,
      75954,
      75957,
      75958,
      75965,
      75966,
      75967,
      75968,
      75969,
      75979,
      75980,
      75981,
      76102,
      76113,
      76114,
      76115,
      76118,
      76119,
      76120,
      76122,
      76123,
      76124,
      76125,
      76126,
      76127,
      76128,
      76130,
      76131,
      76137,
      76138,
      76139,
      76140,
      76141,
      76142,
      76143,
      76144,
      76146,
      76147,
      76148,
      76149,
      76150,
      76151,
      76152,
      76153,
      76157,
      76158,
      76159,
      76160,
      76162,
      76163,
      76164,
      76165,
      76166,
      76167,
      76895,
      76896,
      76897,
      76898,
      76899,
      80006,
      80007,
      80008,
      80009,
      80010,
      80011,
      80012,
      80013,
      80014,
      80015,
      80016,
      8293,
    ],
  },
];
